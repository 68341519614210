import React, {useState} from 'react';
import { Button, Form } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { loginAPI } from "../../../api/user";
import { useAuth } from '../../../hooks';
import "./LoginForm.scss";


export function LoginForm() {
    const { login } = useAuth();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        validateOnChange:false,
        validateOnBlur:false,
        onSubmit: async (formValue) => {
            try {
               
                const response = await loginAPI(formValue);
              
                const { token } = response;
               
                login(token);
            } catch (error) {
                toast.error(error.message);
                
            }
        }
    });
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE  ){
        return (<h1 class="text-center">Your browser is not compatible with this website, please upgrade to a more modern browser.<br></br><br></br></h1>);
    } else {
    return (
        <>

        <Form className="login-form-admin" target='_top' onSubmit={formik.handleSubmit}>
            <div className="field ui input labelinput">
          
            <Form.Input 
                name="email"
                label="Email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
            />
         
            </div>
            <div className="field ui input labelinput">
            <Form.Input 
                name="password"
                label="Password"
                placeholder="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
            />
            </div>
            
            <Button type="submit" content="log in" primary fluid />      
        </Form>
        </>
    );
    }
}

function initialValues() {
    return {
        email: "",
        password: "",
    }
}

function validationSchema() { 
    return {
        email: Yup.string().email("No es una cuenta de correo válida").required("Es necesario indicar un email válido"),
        password: Yup.string().required("Es necesario introducir la contraseña"),
    }
}
