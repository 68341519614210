//declare method addDays to calculate tritation days
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export function Calculator(data, pump,pumpcapacity) {

  //console.log("INIT CALCULATOR:::::::::::::::::::");
  

  this.errors = [];
  this.alerts= [];

  this.maxrunsperrecharge=8000;
  this.currentruns=0;

  //valor fijo en excel campo AF14 (old) AG14 (new)
  this.requiredpumpsurplus = 2;

  //valor no especificado en X7(old) Y7(new) (marcado como input oculto)
  this.bolusaday = 0;

  //valor no especificado en Y7(old) Z7(new)
  this.extrapercentagedailydose = 0;

  //valor no especificado en BJ14. No especifica si es SUM(BJ7:BJ13)
  this.volumeofeachnewdilution = 0;

  //P7 (old/new) DO YOU WANT TO USE OTHER LOWER CONCENTRATION? REMEMBER AJ12>0,048 (Campo vacío)
  this.lowerconcentration = 0;


  //AM14,AT14 (old) //AP14, AW14,BE14 ,AO14(new)
  this.totalpumpvolume_pre=20

  this.data = data;
  this.data.pumpcapacity=pumpcapacity;


  //asignamos el pump del paciente para futuras consultas a sus datos
  this.pump = pump;

  ////DEFAULTS

  this.dayssinceprevious_default=15;
  

  this.containz = function(a, obj) {
    var i = a.length;
    while (i--) {
       if (a[i] === obj) {
           return true;
       }
    }
    return false;
}

this.cleanUpDrug=function(drug){
  drug.concentration=parseFloat(drug.concentration);
  drug.dailydose=parseFloat(drug.dailydose);
  return drug;
}

this.cleanUpInputData= function(data) {
  var este=this;
  data.primarydrug[0]=this.cleanUpDrug(data.primarydrug[0]);
  data.otherdrugs.map(function(e) {
    return este.cleanUpDrug(e);
  });
  return data;
}



this.addError = function(text) {
  if (text== undefined){
    text="Incomplete data, cannot complete";
  }
  if(!this.containz(this.errors,text)){
  //if (!this.errors.includes(text)) {
    this.errors.push(text);
  }
}

this.addAlert = function(text) {
  if (text== undefined){
    text="Incomplete data, cannot complete";
  }
  if(!this.containz(this.alerts,text)){
  //if (!this.errors.includes(text)) {
    this.alerts.push(text);
  }
}

  this.calculateRecharge = function() {

    //CLEANUP INPUT
    this.data=this.cleanUpInputData(this.data);


    if (this.data.primarydrug.length==0){
      this.addError("A primary drug is required");
      return {errors: this.errors};
    } else  {
      console.log(this.data.primarydrug[0]);
      if(this.data.primarydrug[0].dailydose==0){
        this.addError("Daily dose is zero");
      return {errors: this.errors};
      }
    }

    if (!this.data.pumprefillday){
      this.addError("Pump refill day is required");
      return {errors: this.errors};
    }

    var finalvolumes = [];
    finalvolumes.push(this.finalVolume(this.data.primarydrug[0]));
    var este = this;
    this.data["otherdrugs"].forEach(function(d) {
      finalvolumes.push(este.finalVolume(d));
    });

    var volumesrequiredArr = [];
    volumesrequiredArr.push(this.volumesrequired(this.data.primarydrug[0]));
    var este1 = this;
    this.data["otherdrugs"].forEach(function(d) {
      volumesrequiredArr.push(este1.round(este1.volumesrequired(d)));
    });

    var drugconcentrationsArr = [];
    drugconcentrationsArr.push(this.NANcheck(this.drugconcentrations(this.data.primarydrug[0])));
    var este2 = this;
    this.data["otherdrugs"].forEach(function(d) {
      drugconcentrationsArr.push(este2.drugconcentrations(d));
    });

    var volumeofnaclArr = [];
    volumeofnaclArr.push(
      this.volumeofnaclfornewdilutionnewtotalvolume(this.data.primarydrug[0])
    );
    var este3 = this;
    this.data["otherdrugs"].forEach(function(d) {
      volumeofnaclArr.push(este3.volumeofnaclfornewdilutionnewtotalvolume(d));
    });

    var totalfinalvolume=0.0;
    finalvolumes.forEach(function(v){
        totalfinalvolume+=v;
    });

    totalfinalvolume=this.NANcheck(totalfinalvolume);

    //beautify outputs
    var volumesrequired_output=[];
    var remember_output=[];
    var mydrugs=this.alldrugs();
    var este=this;
    mydrugs.forEach(function(d,i){

      var pdrug={};
      var rdrug={};
      pdrug["name"]=d.drug.name;
      pdrug["unit"]=d.drug.unit;
      pdrug["unitsingular"]=d.drug.unitsingular;

      rdrug["name"]=d.drug.name;
      rdrug["unit"]=d.drug.unit;
      rdrug["unitsingular"]=d.drug.unitsingular;

      if (volumesrequiredArr.length >=(i-1)){
        pdrug["units"]=este.NANcheck(este.round(volumesrequiredArr[i],1));
      } else {
        este.addError("Volumes required error");
      }

      rdrug["dailydose"]=este.NANcheck(este.round(drugconcentrationsArr[i],3));
      //rdrug["dailydose"]=este.NANcheck(drugconcentrationsArr[i]);
      rdrug["realfinalconcentration"]=este.NANcheck(este.realfinalconcentrationofdruginproposedmixture(d));
      volumesrequired_output.push(pdrug);
      remember_output.push(rdrug);
    });

    var volumeofnacltotal=0.0;
    volumeofnaclArr.forEach(function(v){

        volumeofnacltotal+=v;
    });
    volumeofnacltotal=this.NANcheck(volumeofnacltotal);

    //ERROR CHECKS ::::::::::::::::::::::::::::::::::::::::::
    //=IF((BV7-(BQ14*I7))<0, "Impossible!! The volume in the pump is insufficient to carry out the treatment during the selected period. Decrease the days or increase the loaded volume",
    //console.log("XXXXXXXXXXX",this.data.refillamount,this.continuousdailyflowfunction(this.data.primarydrug[0]),this.data["daysuntilnextrefill"])
    //console.log(this.continuousdailyflowfunction(this.data.primarydrug[0]));
    //console.log(this.data.primarydrug[0]);
    if((this.data.refillamount-(this.continuousdailyflowfunction(this.data.primarydrug[0])*this.data["daysuntilnextrefill"]))<0){
      this.addError("Impossible!! The volume in the pump is insufficient to carry out the treatment during the selected period. Decrease the days or increase the loaded volume");
      return {errors: this.errors};
    }

    //(IF((BW7-(BQ14*I7))<0, "Attention!! The alarm will sound before the selected treatment period. Decrease the days or increase the loaded volume",

    if((this.realfinalvolumeminussurplus()-(this.continuousdailyflowfunction(this.data.primarydrug[0])*this.data["daysuntilnextrefill"]))<0.0){
      this.addError( "Attention!! The alarm will sound before the selected treatment period. Decrease the days or increase the loaded volume");
      return {errors: this.errors};
    }
    //(IF((BW7-(CG14*60))<0,"Watch out!! There is little margin volume left to make titrations of +0,5mcg/d every 15 days for 60 days"," ")))))
    
    if( (this.realfinalvolumeminussurplus()-( this.continuousdailyflowfunction(this.data.primarydrug[0])*60 )) <0 ){
      this.addAlert( "Watch out!! There is little margin volume left to make titrations of +0,5mcg/d every 15 days for 60 days");
      //return {errors: this.errors};
    }


    //END ERROR CHECKS ::::::::::::::::::::::::::::::::::::::::::

    var outputs = {
      finalvolume: this.data.refillamount,
      volumesrequired: volumesrequiredArr,
      volumesrequired_output:volumesrequired_output,
      volumeofnacl: this.round(volumeofnacltotal), //DA 0 TODOS LOS CAMPOS EN EXCEL, campo relativo a inputs que no son activos
      drugconcentrations: drugconcentrationsArr,
      finalsurplus: this.finalsurplus(),
      remember_output:remember_output,

      errors: this.errors,
      alerts:this.alerts,
    };



    return outputs;

  }

  this.formatExcel=function(data){
    //EXCEL FORMATED output

    var D={
      "Final volume required to fill the pump completely with the proposed dilution":data.finalvolume,
      'Volume of "NaCl 9 mg/ml (0,9 %)" required for the new dilution and for new total volume':data.volumeofnacl,
      //'Final surplus at next refill':
    }
  }

  this.pumpcapacity = function() {
    //temporary function to make sure this is a user input value or not
    //return this.patientpumpcapacity;
    //return this.data.refillamount;
    return this.data.pumpcapacity;
  }

  this.NANcheck=function(v){
    if (isNaN(v)){
      return 0;
    } else{
      return v;
    }
  }

  //CG14
  //=AT14*($CG$7)/($AT$7)
  this.continuousdailyflowmedio=function(){
    var result=0;

    //=AT7+((0.5*0.75)+(1*0.5)+(1.5*0.25))
    var CG7=this.data.primarydrug[0]["dailydose"] + ( (0.5*0.75)+ (1*0.5 )+(1.5*0.25) )

    result=this.continuousdailyflow()*(CG7 ) / ( this.data.primarydrug[0]["dailydose"] );
    return result;
  }

  //AM7 (old) 
  this.finalVolume = function(drug) { 
    //AM7
    if (!drug){
      this.addError("No drug supplied");
      return false;
    }
    if (drug["dailydose"] == 0) return 0;
    else {
      //$AM$14*AH7/$AH$14 (old)
      //$BM$14*(AJ7/$AI$14) (new)
      var result = 0.0;
    
      result =this.data.refillamount * (this.necessaryvolumeMinimumVolumesRequired(drug)) / (this.requiredpumpsurplus + this.requiredDilutionVolumes())
      return result;
    }
  }

  //U7 (new)
  //=IF(G7=0,0,IF(P7=0,((D7/O7)*L7),((D7/P7)*L7)))
  this.DAILYVOLUMETHATISCONTINUOUSLYINFUSEDWITHTHENEWDILUTION=function(drug){
    var result=0;
    if (drug){
      
      if(drug.dailydose>0){
        if (this.lowerconcentration ==0){
          result=(drug.concentration/this.necessarydilutionmaximumcibcebtration(drug))*this.continuousflowdailyvolumeDrug(drug);
        } else {
          result=((drug.concentration/this.lowerconcentration )*this.continuousflowdailyvolumeDrug(drug));
        }
      }
    }
    return result;
  }

  //U14 (new)
  //=IF(G7=0,0,IF(P7=0,((D7/O7)*L7),((D7/P7)*L7)))
  this.DAILYVOLUMETHATISCONTINUOUSLYINFUSEDWITHTHENEWDILUTION=function(drug){

    var result=0;
    if (drug){
      if(drug.dailydose>0){
        if (this.lowerconcentration==0){
          result=((drug.concentration / this.necessarydilutionmaximumcibcebtration(drug)*this.continuousflowdailyvolumeDrug(drug)));
        } else {
          result=((drug.concentration / this.lowerconcentration)*this.continuousflowdailyvolumeDrug(drug));
        }
      }
    }
    return result;
  }

  //V7 (new)
  //=IF(G7=0,0,((($Z$7/100)*$U$14)*U7)/$U$14)
  this.DAILYVOLUMETHATISINFUSEDWITHeachBoluseswithTHENEWDILUTION=function(drug){
    var result=0;
    if (drug){
      if(drug.dailydose>0){
        result=(((this.extrapercentagedailydose/100)*this.DAILYVOLUMETHATISCONTINUOUSLYINFUSEDWITHTHENEWDILUTION(drug))*this.DAILYVOLUMETHATISCONTINUOUSLYINFUSEDWITHTHENEWDILUTION(drug))/this.DAILYVOLUMETHATISCONTINUOUSLYINFUSEDWITHTHENEWDILUTION(drug);

      }
    }
    return result;
  }

  //AG7 (new)
  //=(AF7/$AF$14)*$AG$14
  this.REMAININGVOLUMEREQUIREDBYTHEPUMPFORCORRECTOPERATION=function(drug){
    var result=0;
    result=(this.requiredDilutionVolume(drug)/this.requiredDilutionVolumes())*this.requiredpumpsurplus;
    return result;
  }

 


  //AE7 (old) AF7 (new)
  //=IF(G7=0,0,(U7*$I$7)+(V7*$Y$7*$I$7))
  this.requiredDilutionVolume = function(drug) { //ERROR
    var result=0;

    if (drug){
      
      if(drug.dailydose>0){
        var first=0;
        var second=0;
        
        //(U7*I7)
        first=(this.DAILYVOLUMETHATISCONTINUOUSLYINFUSEDWITHTHENEWDILUTION(drug)*this.data.daysuntilnextrefill);
        
        //(V7*Y7*I7)
        second=(this.DAILYVOLUMETHATISINFUSEDWITHeachBoluseswithTHENEWDILUTION(drug)*this.bolusaday*this.data.daysuntilnextrefill)

        result=first+second;
      }
    }

    return result;
  }


  //AF14 (old/new)
  //=IF(SUM(AF7:AF13)<=C3,SUM(AF7:AF13), "PUMP CAPACITY EXCEEDED. REDUCE DAYS")
  this.requiredDilutionVolumes = function() {
    /*
    var result=0;
    if (this.data.primarydrug[0].dailydose==0){
      result=0;
    } else {
      var first=0;
      var second=0;

      first=(U7*I7);

      second=(V7*Y7*I7)

      result=first+second;
    }

    return result;
    */
  
    
    var este=this;

    var arrayofdrugs = [];
    arrayofdrugs = this.alldrugs();

    var result = 0.0;
    //map para aplicar formula a cada valor del array
    var requiredvolumesofdrugs = arrayofdrugs.map(function(e) {
      result= este.requiredDilutionVolume(e);
      return result;
    });
    //se suman los datos del array
    if (requiredvolumesofdrugs.length == 0) {
      this.addError("There are no values of volumes of drugs");
    } else {
      result = requiredvolumesofdrugs.reduce(function(acc, curr) {
        result= acc + curr;
        return result;
      });
    }

    if (result <= this.pumpcapacity()) {
      result= result;
      return result;
    } else {
      //this.addError("Pump capacity exceeded. Reduce days");
    }

  }


  //Z7
  this.bolusdose = function(drug) {
    var bolus = 0.0;
    bolus = (this.extrapercentagedailydose / 100.0) * drug["dailydose"];
    return bolus;
  }

  //=AF7+AE7
  this.necessaryvolumeMinimumVolumesRequired = function(drug) {
    //AH7
    var result = 0.0;
    result = this.remainingvolumesurplus(drug) + this.necessaryvolumeRequiredDilutionVolumesDrug(drug);

    return result;
  }

  //=(AE7/$AE$14)*$AF$14
  this.remainingvolumesurplus = function(drug) {
    //AF7
    var result = 0.0;
    result = (this.necessaryvolumeRequiredDilutionVolumesDrug(drug) / this.requiredDilutionVolumes()) * this.requiredpumpsurplus;

    return result;
  }

  //=IF(G7=0;0;(U7*$I$7)+((Z7/O7)*$X$7*$I$7))
  this.necessaryvolumeRequiredDilutionVolumesDrug = function(drug) {
    //AE7
    if (drug.dailydose == 0) return 0.0;
    else {
      //(U7*$I$7)+((Z7/O7)*$X$7*$I$7)
      var result = 0.0;
      result = (this.dailyvolumecontinuoslyinfused(drug) * this.data.daysuntilnextrefill) + ((this.doseofeachdrugintoeachbolus(drug) / this.necessarydilutionmaximumcibcebtration(drug)) * this.givebolustopatientaday(drug) * this.data.daysuntilnextrefill);

      return result;
    }
  }

  //U7 (old/new)
  //=IF(G7=0;0;IF(P7=0;((D7/O7)*L7);((D7/P7)*L7)))
  this.dailyvolumecontinuoslyinfused = function(drug) {
    var result = 0.0;
    if (drug){
    //U7
    if (drug.dailydose == 0) return 0;
    else if (this.lowerconcentration == 0) {
      //(D7/O7)*L7)

      result = ((drug.concentration / this.necessarydilutionmaximumcibcebtration(drug)) * this.continuousflowdailyvolumeDrug(drug));
      return result;
    } else {
      //(D7/P7)*L7
      var result = 0.0;
      result = ((drug.concentration / this.lowerconcentration) * this.continuousflowdailyvolumeDrug(drug));
      return result;
    }
    }
    return result;
  }

  //=G7/D7
  this.continuousflowdailyvolumeDrug = function(drug) {
    //L7
    var result = 0.0;
    result = drug.dailydose / drug.concentration;
    return result;
  }

  //=(Y7/100)*G7
  this.doseofeachdrugintoeachbolus = function(drug) {
    //Z7

    var result = 0.0;
    result = (this.extrapercentagedailydose / 100.0) * drug.dailydose; //this.data.primarydrug[0].dailydose;
    return result;
  }

  //O7 (old/new)
  //=IF(G7=0,0,IF(SUM($G$8:$G$13)>0,100,IF($L$14>=$M$7,100,IF(AND($L$14>=($M$7/4),L14<$M$7),25,IF(AND($L$14>=($M$7/10),L14<($M$7/4)),10,IF(L14<=($M$7/10),5,5))))))
  //=IF(G7=0;0;IF(SUM($G$8:$G$13)>0;100;IF($L$14>=$M$7;100;IF(AND($L$14>=($M$7/4);L14<$M$7);25;IF(AND($L$14>=($M$7/10);L14<($M$7/4));10;IF(L14<=($M$7/10);5;5))))))
  this.necessarydilutionmaximumcibcebtration = function(drug) {

    this.currentruns++;

    //TODO:FIXME: Ya funciona el switch, devuelve datos que se tienen que acabar de confirmar
    
    switch (drug.drug.ismaindrug) {
      case true:
        var result1 = 0.0;
        if (drug.dailydose > 0) {
          if (this.sumofdailydoseofotherdrugs() > 0) {
            result1 = 100.0;
          } else {
            if (
              this.sumofcontinuousflowdailyvolume() >= this.necessarydilution()
            ) {
              result1 = 100.0;
            } else {
              if (
                this.sumofcontinuousflowdailyvolume() >=
                this.necessarydilution() / 4 &&
                this.sumofcontinuousflowdailyvolume() < this.necessarydilution()
              ) {
                result1 = 25.0;
              } else {
                if (
                  this.sumofcontinuousflowdailyvolume() >=
                  this.necessarydilution() / 10 &&
                  this.sumofcontinuousflowdailyvolume() <
                  this.necessarydilution() / 4
                ) {
                  result1 = 10.0;
                } else {
                  if (
                    this.sumofcontinuousflowdailyvolume() <=
                    this.necessarydilution() / 10
                  ) {
                    result1 = 5.0;
                  } else {
                    result1 = 5.0;
                  }
                }
              }
            }
          }
        }
        return result1;
        break;
        //=IF(G8=0;0;IF(($L$14>=$M$7);D8;G8/((($M$7-$L$7)/COUNTIF($L$8:$L$13;"<> 0")))))
      case false:

        var result2 = 0.0;
        if (this.sumofcontinuousflowdailyvolume() >= this.necessarydilution()) {
          result2 = drug.concentration;
        } else {
          result2 =
            drug.dailydose /
            ((this.necessarydilution() -
                this.continuousflowdailyvolumeDrug(drug)) /
              this.sumofcontinuousflowdailyvolumeOtherDrugsNotEqualToZero());
        }
 
        return result2;
        break;
        //D8 --> drug.concentration
        //G8 --> drug.dailydose
        //($M$7-$L$7) --> (this.necessarydilution() - this.continuousflowdailyvolumeDrug(drug))
        //COUNTIF($L$8:$L$13),{"<> 0") --> sumofcontinuousflowdailyvolumeOtherDrugsNotEqualToZero()
      default:
        this.addError("Problem knowing wether is main drug or not");
    }
  }

  this.necessarydilution = function() {
    //M7
    var minimumdailyflow = parseFloat(this.pump.fixedflows[0]);
    if (!isNaN(minimumdailyflow)) {
      return minimumdailyflow;
    } else {
      this.addError("Selected pump does not have a minimum fixedflow");
      return 0.0;
    }
  }

  //X7 campo vacío //FIXME:TODO:
  this.givebolustopatientaday = function() {
    //DO YOU WANT TO GIVE BOLUS TO THE PATIENT? How many a day?
    return 1;
  }

  //SUM($G$8:$G$13) - Suma la dosis diaria del resto de drogas, sin contar Primary Drug
  this.sumofdailydoseofotherdrugs = function() {
    var result = 0.0;
    if (this.data.otherdrugs.length == 0) {
      result = 0.0;
    } else {
      result = this.data.otherdrugs.reduce(function(acc, curr) {
        return acc.dailydose + curr.dailydose
      });
    }
    return result;
  }

  ////$L$14 =SUM(L7:L13)
  this.sumofcontinuousflowdailyvolume = function() {
    //L14
    var result = 0.0;
    result =
      this.continuousflowdailyvolumeDrug(this.data.primarydrug[0]) + this.sumofcontinuousflowdailyvolumeOtherDrugs();
    return result;
  }

  this.sumofcontinuousflowdailyvolumeOtherDrugsNotEqualToZero = function() {
    var notequaltozero = 0;
    for (var i = 0; i < this.data.otherdrugs.length; i++) {
      if (this.data.otherdrugs[i].dailydose / this.data.otherdrugs[i].concentration != 0) {
        notequaltozero++;
      }
    }
    return notequaltozero;
  }

  this.sumofcontinuousflowdailyvolumeOtherDrugs = function() {
    var sum = 0;

    for (var i = 0; i < this.data.otherdrugs.length; i++) {
      sum += this.data.otherdrugs[i].dailydose / this.data.otherdrugs[i].concentration;
    }
    return sum;
  }

  //BE7 (new) and AP7 (new)
  //=IF(G7=0,0,($AO$14*(AJ7/$AI$14)))
  this.VOLUMEOFEACHDRUGREQUIREDFORTHENEWDILUTIONANDFORNEWTOTALVOLUME=function(drug){
 
    var result=0;
    if (drug){
      if(drug.dailydose>0){
        result=(this.totalpumpvolume_pre
          *(this.VOLUMEOFTHISDRUGREQUIREDFORTHENEWDILUTION_SURPLUSPUMP(drug)/this.TOTALVOLUMEREQUIREDINTHEPUMPFORTHEESTABLISHEDPERIOD_REQUIREDPUMPSURPLUS()));
      }
    }
    /*
    if (drug.drug.id==2){

 
      this.currentruns++;
      if (this.currentruns>this.maxrunsperrecharge){
       // console.log(this.currentruns);
        this.addError("Max iterations reached, stack overflow error");
        return 200000;
      }
      //console.log(this.continuousdailyflow(),this.continuousdailyflowComplex())
      //console.log("ERROR ZONE")
      result=result*(this.continuousdailyflow()/this.continuousdailyflowComplex()) //ESTO DA ERROR
      
    }
    */
    result=this.round(result,1);
 
    return result;
  }

  //BB7 (old) BN7 (old2) BE(new)
  //=IF(G7=0,0,($BM$14*(AJ7/$AI$14))) OLD
  //=ROUND(AX7,1) y =ROUND(AX8*(AZ14/BJ14),1) si es morphine
  this.volumesrequired = function(drug) {
  
    var result=0;
    if (drug){
       
        //BM14*(AJ7/AI14))
        //=ROUND(AX7,1)
        if(drug.dailydose>0){
          result=this.data.refillamount*(this.VOLUMEOFTHISDRUGREQUIREDFORTHENEWDILUTION_SURPLUSPUMP(drug)/this.TOTALVOLUMEREQUIREDINTHEPUMPFORTHEESTABLISHEDPERIOD_REQUIREDPUMPSURPLUS())
        }
        //if (drug.drug.id==2){
         
          //if morphine 
          //=ROUND(AX8*(AZ14/BJ14),1)
          //result=result*(this.continuousdailyflow()/this.continuousdailyflowComplex())
        //} 
    }
    return result;
    /*
    if (drug== undefined){
      this.addError();
      return false;
    }
    var result = 0.0;
    result = (drug.dailydose == 0) ? 0.0 : (this.pumpcapacity() * (this.volumerequiredfornewdilution(drug) / (this.requiredpumpsurplus + this.requiredDilutionVolumes())));

    return result;//parseFloat(result.toFixed(1));
    */
  }

  //AI7 => ($I$7*(G7/D7))+(($Y$7/100)*$X$7*(G7/D7)*$I$7)+(IF(P7=0;((O7/D7)*AF7);(((O7/D7)*AF7)/(O7/P7)))) => 0,725
  //AI9 => ($I$7*(G9/D9))+(($Y$7/100)*$X$7*(G9/D9)*$I$7)+(IF(P9=0;((O9/D9)*AF9);(((O9/D9)*AF9)/(O9/P9)))) => 0,145
  //AI10 => ($I$7*(G10/D10))+(($Y$7/100)*$X$7*(G10/D10)*$I$7)+(IF(P10=0;((O10/D10)*AF10);(((O10/D10)*AF10)/(O10/P10)))) => 1,450
  this.volumerequiredfornewdilution = function(drug) {
    var result1 = 0.0;
    var result2 = 0.0;
    result1 = (this.data["daysuntilnextrefill"] * (drug.dailydose / drug.concentration)) + ((this.extrapercentagedailydose / 100) * this.givebolustopatientaday(drug) * (drug.dailydose / drug.concentration) * this.data["daysuntilnextrefill"]);
    result2 = (this.lowerconcentration == 0) ? ((this.necessarydilutionmaximumcibcebtration(drug) / drug.concentration) * this.remainingvolumesurplus(drug)) :
      (((this.necessarydilutionmaximumcibcebtration(drug) / drug.concentration) * this.remainingvolumesurplus(drug)) / (this.necessarydilutionmaximumcibcebtration(drug) / this.lowerconcentration));
    return result1 + result2;
  }

  //drugconcentrations() => array con valores de cada drug en BG7:BG13
  //=IF(B7=$C$7;G7;$AW$14*(ROUND(AU7;1)/$AT$14)*D7) old
  //=IF(B7=$C$7,G7,$BJ$14*BK7)
  this.drugconcentrations = function(drug) {

    // =IF(B7 = $C$7),
    var result = 0.0;
    //console.log(this.data.primarydrug[0].drug.id);
    //console.log(drug.drug.id,this.data.primarydrug[0].drug.id);
    if (drug.drug.id==this.data.primarydrug[0].drug.id){
      //console.log("esta pasando",drug);
      result=this.data.primarydrug[0].dailydose;
    } else {
      //$BJ$14*BK7
      ////ESTAMOS OPERANDO AQUI XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
      //console.log("this.continuousdailyflowComplex()",this.continuousdailyflowComplex())
      result=this.continuousdailyflowComplex()*this.realfinalconcentrationofdruginproposedmixture(drug);
      //result=0.44118*this.realfinalconcentrationofdruginproposedmixture(drug); //correctisssimo
    }
    //result = (drug.drug.ismaindrug) ? drug.dailydose : (this.continuousdailyflow(drug) * (this.volumesrequired(drug) / this.pumpcapacity()) * drug.concentration);
   
    return result;//parseFloat(result.toFixed(3));
  }

  //Helper
  this.alldrugs = function() {
    var alldrugsdata = this.data;
    var alldrugs = [];

    alldrugs.push(alldrugsdata.primarydrug[0]);

    alldrugsdata.otherdrugs.forEach(function(d) {
      alldrugs.push(d);
    })

    return alldrugs;
  }

  //AZ14
  // ESTE ESTA MAL XXXXXX (el redondeo esta regulinchi pero ceo que no esta mal)
  //BJ14 (new) => =IF(MATCH(C7,B7:B13,0),(VLOOKUP(C7,B6:F13,MATCH(E6,B6:F6,0),0))/(VLOOKUP(C7,B6:F13,MATCH(F6,B6:F6,0),0)),0)
  this.continuousdailyflowComplex= function() {
  
    var result=0;
    var este=this;
    //console.log(this.round(this.realfinalconcentrationofdruginproposedmixture(this.data.primarydrug[0]),1));
   
      result=this.data.primarydrug[0].dailydose/this.realfinalconcentrationofdruginproposedmixture(this.data.primarydrug[0]);
      //result=this.round(result,2);
     

    return result;
  }

  //$AW$14 => =SUM(AW7:AW13) ( old)
  //BQ14 (new) => =IF(MATCH(C7,B7:B13,0),(VLOOKUP(C7,B6:F13,MATCH(E6,B6:F6,0),0))/(VLOOKUP(C7,B6:F13,MATCH(F6,B6:F6,0),0)),0) (new)
  this.continuousdailyflow = function() {
    
    //=SUM(AW7:AW13) --> SUM(AP7:AP13) --> SUM(U7:U9)
    //=IF(G7=0;0;IF(P7=0;((D7/O7)*L7);((D7/P7)*L7)))
    var este=this;
    var arrayofdrugs = [];
    arrayofdrugs = this.alldrugs();

    var result = 0.0;
    //map para aplicar formula a cada valor del array
    var arrayofcontinuousdailyflow = arrayofdrugs.map(function(e) {
      return este.continuousdailyflowfunction(e)
    });

    //se suman los datos del array
    if (arrayofcontinuousdailyflow.length == 0) {
      this.addError("There are no values of continuous daily flow");
    } else {
      result = arrayofcontinuousdailyflow.reduce(function(acc, curr) {
        return acc + curr
      });
    }

    return result;

  }

  this.continuousdailyflowfunction = function(drug) {

    var result = 0.0;
    if (drug.dailydose == 0) return 0.0;
    else {
      result = (this.lowerconcentration == 0) ? ((drug.concentration / this.necessarydilutionmaximumcibcebtration(drug)) * this.continuousflowdailyvolumeDrug(drug)) : ((drug.concentration / this.lowerconcentration) * this.continuousflowdailyvolumeDrug(drug));
      //console.log("continuousdailyflowfunction",result);
      return result;
    }
  }




  //volumeofnacl() => Un solo valor de BC7:13
  //=SUM(BD7:BD13)
  this.volumeofnacl = function() {
    //=ROUND(AV7;1) -> ROUND(AO7;1)
    //+(...) Suma de todos los campos
    //ROUND(AV13;1) -> ROUND(A013;1)
    var result = 0.0;
    // result = this.volumeofnaclArr.reduce((a, b) => a + b, 0);
    return result;
  }

  //AO7
  //=IF(G7=0;0;($AM$14*(AJ7/$AH$14)))
  this.volumeofnaclfornewdilutionnewtotalvolume = function(drug) {
    var result = 0.0;
    result = (drug.dailydose == 0) ? 0.0 : (this.pumpcapacity() * (this.volumeofnaclsurpluspump(drug) / (this.requiredpumpsurplus + this.requiredDilutionVolumes())));
    return result;//parseFloat(result.toFixed(1));
  }

  //AJ7
  //=IF(P7=0;((1-(O7/D7))*(Q7/(O7/D7)))+((1-(O7/D7))*AF7);(1-(P7/D7))*(Q7/(P7/D7))+((1-(P7/D7))*AF7))
  this.volumeofnaclsurpluspump = function(drug) {
    var result = 0.0;
    //((1-(O7/D7))*(Q7/(O7/D7)))+((1-(O7/D7))*AF7)
    result =
      this.lowerconcentration == 0 ?
      ((1 - (this.necessarydilutionmaximumcibcebtration(drug) / drug.concentration)) * (this.volumeofdrugrequirednewdilution(drug) / (this.necessarydilutionmaximumcibcebtration(drug) / drug.concentration))) + ((1 - (this.necessarydilutionmaximumcibcebtration(drug) / drug.concentration)) * this.remainingvolumesurplus(drug)) : //(1-(P7/D7))*(Q7/(P7/D7))+((1-(P7/D7))*AF7)
      (1 - (this.lowerconcentration / drug.concentration)) * (this.volumeofdrugrequirednewdilution(drug) / (this.lowerconcentration / drug.concentration)) + ((1 - (this.lowerconcentration / drug.concentration)) * this.remainingvolumesurplus(drug));
    return result;
  }

  //Q7
  // =($I$7*(G7/D7))+(($Y$7/100)*$X$7*(G7/D7)*$I$7)
  this.volumeofdrugrequirednewdilution = function(drug) {
    var result = 0.0;
    result = (this.data.daysuntilnextrefill * this.continuousflowdailyvolumeDrug(drug)) + ((this.extrapercentagedailydose / 100) * this.bolusaday * this.continuousflowdailyvolumeDrug(drug) * this.data.daysuntilnextrefill);
    return result;
  }

  //BY7 (new)
  //=IF($BM$14=0,($BE$14-($BJ$14*$I$7)),($BM$14-($BJ$14*$I$7)))
  this.finalsurplus = function() {
    var result = 0.0;
    if (this.datarefillamount==0){
      result=(this.totalpumpvolume_pre-(this.continuousdailyflow()*this.data.daysuntilnextrefill));
    } else {
      result=(this.data.refillamount-(this.continuousdailyflow()*this.data.daysuntilnextrefill));
    }
    /*
    result =
      this.volumeofeachnewdilution == 0 ? //($BB$14-($BE$14*$I$7)) BB14 = AT14 = AM14 = G3
      (this.totalpumpvolume_pre - (this.dailyvolumecontinuoslyinfusedroundeddilution() * this.data.daysuntilnextrefill)) : //($BJ$14-($BE$14*$I$7))
      (this.volumeofeachnewdilution - (this.dailyvolumecontinuoslyinfusedroundeddilution() * this.data.daysuntilnextrefill));
     if (isNaN(result)) {
       return 0;
     }
     */
    return result;
  }

  //BG7
  //=IF(B7=$C$7,G7,$AW$14*(ROUND(AU7,1)/$AT$14)*D7)
  this.dailydoseofeachdrugtobeadministeredtothepatient = function(drug) {
    if (drug==undefined){
      this.addError("No drug was supplied");
      return false;
    }
    var result = 0.0;
    if (drug.ismaindrug) {
      result = drug.dailydose
    } else {
      result = this.continuousdailyflow() * ((this.volumesrequired(drug) / this.data.refillamount) * drug.concentration);
    }

    return result;
  }

  //Array de valores de BG7:BG13
  this.dailydoseadministered = function(){
    var este=this;
    var arrayofdrugs = [];
    arrayofdrugs = this.alldrugs();

    var dailydoseadministered = arrayofdrugs.map(function(e) {
      return este.dailydoseofeachdrugtobeadministeredtothepatient(e)
    });

    return dailydoseadministered;

  }

  //BW7 => Volume available to us Real Final Volume in the Pump - Surplus (ml)
  //=IF(BM14=0,BE14-AG14,BM14-AG14)
  this.realfinalvolumeminussurplus=function(){
    var result=this.data.refillamount-this.requiredpumpsurplus;
    return result;
  }

  //BH7 (old) BR7 (new)// => columna F de titration necesita array con el resultado de esta formula para cada drug. Usar helper alldrugs?? TODO:
  //=(ROUND(BB7,1)*(IF(P7=0,D7,P7)))/$BB$14 => primarydrug // OLD

  //=(ROUND(BE7,1)*(D7)/$BE$14)
  
  this.realfinalconcentrationofdruginproposedmixture = function(drug) {
    // TAMBIEN AQUIXXXXXXXXXXXXXXXXX
    if (drug==undefined){
      this.addError("No drug was supplied");
      return false;
    }
    var result = 0.0;
    result=(this.VOLUMEOFEACHDRUGREQUIREDFORTHENEWDILUTIONANDFORNEWTOTALVOLUME(drug)*(drug.concentration)/this.totalpumpvolume_pre);
    //result=this.round(result,1);

    /*
    var second = 0.0;
    if (this.lowerconcentration == 0) {
      // second=this.data.primarydrug[0].concentration;
      second = drug.concentration;
    } else {
      second = this.lowerconcentration;
    }
    result = (this.volumesrequired(drug) * second) / this.data.refillamount;
    */
    return result;
  }

  //AJ7 (new)  
  //=($I$7*(G7/D7)) + (($Z$7/100)*$Y$7*(G7/D7)*$I$7) + (IF(P7=0,((O7/D7)*AG7),(((O7/D7)*AG7)/(O7/P7))))
  this.VOLUMEOFTHISDRUGREQUIREDFORTHENEWDILUTION_SURPLUSPUMP=function(drug){ //INCORRECTA!
    var result=0;

    var first=0;
    var second=0;
    var third=0;

    //(I7*(G7/D7))
    first=this.data.daysuntilnextrefill*(drug.dailydose/drug.concentration); //OK

    //((Z7/100)*Y7*(G7/D7)*I7)
    second=((this.extrapercentagedailydose/100) * this.bolusaday * (drug.dailydose/drug.concentration) * this.data.daysuntilnextrefill); //OK
    
    //(IF(P7=0,((O7/D7)*AG7),(((O7/D7)*AG7)/(O7/P7))))
    if(this.lowerconcentration==0){
      //((O7/D7)*AG7)
      third=(this.necessarydilutionmaximumcibcebtration(drug)/drug.concentration)*this.REMAININGVOLUMEREQUIREDBYTHEPUMPFORCORRECTOPERATION(drug); //FIXING
    } else {
      //(((O7/D7)*AG7)/(O7/P7)))
      third=((this.necessarydilutionmaximumcibcebtration(drug)/drug.concentration)*this.REMAININGVOLUMEREQUIREDBYTHEPUMPFORCORRECTOPERATION(drug)/(this.necessarydilutionmaximumcibcebtration(drug)/this.lowerconcentration))
    }
    /*
    if(this.lowerconcentration==0){
      //((O7/D7)*AG7)
      second= (this.necessarydilutionmaximumcibcebtration(drug)/drug.concentration)*this.remainingvolumesurplus(drug);
    } else {
      //(((O7/D7)*AG7)/(O7/P7))))
      second=(((this.necessarydilutionmaximumcibcebtration(drug)/drug.concentration)*this.remainingvolumesurplus(drug))/(this.necessarydilutionmaximumcibcebtration(drug)/this.lowerconcentration));
    }
    
    result=(this.data.daysuntilnextrefill*(drug.dailydose/drug.concentration))+((this.extrapercentagedailydose/100)*this.bolusaday*(drug.dailydose/drug.concentration)*this.data.daysuntilnextrefill)+second;
    */

    result=first+second+third;

    return result;
  }

  //AI14 and AJ14 (new)
  this.TOTALVOLUMEREQUIREDINTHEPUMPFORTHEESTABLISHEDPERIOD_REQUIREDPUMPSURPLUS=function(){
    return this.requiredDilutionVolumes() + this.requiredpumpsurplus;
  }

  //AP14 (new) =IF(G7=0,0,($AO$14*(AJ7/$AI$14)))
  this.VOLUMEOFEACHDRUGREQUIREDFORTHENEWDILUTIONANDFORNEWTOTAL =function(drug){
    var result=0;
    if (drug){
      
      if(drug.dailydose>0){
        result= (this.totalpumpvolume_pre*(this.VOLUMEOFTHISDRUGREQUIREDFORTHENEWDILUTION_SURPLUSPUMP(drug)/this.TOTALVOLUMEREQUIREDINTHEPUMPFORTHEESTABLISHEDPERIOD_REQUIREDPUMPSURPLUS()));
      }
    } 
    result=this.round(result,1);
    return result;
  }

  //AI7 (new)
  //=AG7+AF7
  this.NECESSARYVOLUMEOFEACHNEWDILUTIONFORTHEPROPOSEDPERIOD_ContinuousInfusion_Boluses_SURPLUSPUMP=function(drug){
    return this.requiredDilutionVolume(drug)+this.remainingvolumesurplus(drug);
  }


  //AO7 (new)
  //=IF(G7=0,0,$AO$14*AI7/$AI$14)
  this.TOTALFINALVOLUMEREQUIREDTOFILLTHEPUMPCOMPLETELYWITHTHEPROPOSEDDILUTION=function(drug){
    var result=0;
    if (drug){
      
      if(drug.dailydose>0){
        result=this.totalpumpvolume_pre*this.NECESSARYVOLUMEOFEACHNEWDILUTIONFORTHEPROPOSEDPERIOD_ContinuousInfusion_Boluses_SURPLUSPUMP(drug)/this.NECESSARYVOLUMEOFEACHNEWDILUTIONFORTHEPROPOSEDPERIOD_ContinuousInfusion_Boluses_SURPLUSPUMP(drug);
      }
    }
    return result;
  }

  //BM7 (new)
  //=$BM$14*AO7/$AO$14
  this.VOLUMEOFEACHNEWDILUTIONFORTHEPROPOSEDVOLUME_ContinuousInfusion_Boluses=function(drug){
    this.data.refillamount*this.TOTALFINALVOLUMEREQUIREDTOFILLTHEPUMPCOMPLETELYWITHTHEPROPOSEDDILUTION(drug)/this.totalpumpvolume_pre;
  }

  this.previousparametersFunction = function() {
    var este=this;
    var arrayofdrugs = [];
    arrayofdrugs = this.alldrugs();

    var previousparametersArr = arrayofdrugs.map(function(e) {
      return este.realfinalconcentrationofdruginproposedmixture(e)
    });

    return previousparametersArr;
  }


  //$BE$14 (old)
  //=IF(MATCH(C7;B7:B13;0);(VLOOKUP( C7;B6:F13;MATCH(E6;B6:F6;0);FALSE() ))/(VLOOKUP(C7;B6:F13;MATCH(F6;B6:F6;0);FALSE()));0)
  this.dailyvolumecontinuoslyinfusedroundeddilution = function() {
 
    var result = 0.0;
    result = this.dailydoseofeachdrugtobeadministeredtothepatient(this.data.primarydrug[0]) / this.realfinalconcentrationofdruginproposedmixture(this.data.primarydrug[0]);

    return result;
  }

  //Syntax: VLOOKUP([value], [range], [column number], [false or true])
  this.tritrationCalculator = function(input, recharge, modificators,addedtiration) {
    var este=this;
    var nextfuturerefilltitration=false;
    if(!recharge){
      this.addError("No recharge was supplied");
      return false;
    }

    modificators = modificators || [];
    addedtiration = addedtiration || {};

    //var dayssinceprevious = 15; //B10
    //O6 => =IF(BJ14=0;BB14;BJ14)
    var volumeoriginalfill = 0.0
    if (this.volumeofeachnewdilution == 0) {
      volumeoriginalfill = this.data.refillamount;
    } else {
      volumeoriginalfill = this.volumeofeachnewdilution;
    }

    //C10 => O6 - (B10 * E10) - formula del remainingvolume en el primer tritation

    //var remainingvolumeatend = volumeoriginalfill - (this.dayssinceprevious_default * this.dailyvolumecontinuoslyinfusedroundeddilution());
    //var continuousdailyflow = this.dailyvolumecontinuoslyinfusedroundeddilution();
    var tritrationsres = [];

    //var previousparameters = this.previousparametersFunction();

    var dailydoseadministered = this.dailydoseadministered();

    var newTitrationDate = "";
    var newparametersuntilnexttritration = [];

    var remainingvoltri = 0.0;

    window.maxruns = 19;
    var runs = 0;
   
    var titration=true;
    var titrations=[];
    var titrationstoapi=[];
    titration,titrations,titrationstoapi,modificators,nextfuturerefilltitration=this.titrationLoop(titration,titrations,titrationstoapi,modificators,nextfuturerefilltitration);
    

    var data={
      "nextfuturerefilltitration":nextfuturerefilltitration,
      "titrations":titrations,
      "titrations_to_API":titrationstoapi
    }

    return data;
  }

  this.titrationLoop=function(titration,titrations,titrationstoapi,modificators,nextfuturerefilltitration){
    var runs=titrations.length;
    var este=this;
    
    titration= titration|| true;
    if (titration){
      var previoustitration=false;
     
      if (titrations.length>0){
        previoustitration=titrations[titrations.length - 1];
      }
     
      var hasmodificator=false;
      if (modificators.length>0) {
      
        modificators.forEach(function(m, i) {
          if (m){
            if (runs == m.index) {
            
              //matched the tritratrion to modify
              titration=este.calculateIndividualTitration(previoustitration,m,runs);
              hasmodificator=true;
              if (titration){
                titrationstoapi.push(titration.output_api);
              }
            }
          }
        });
      }
      if (!hasmodificator){
        titration=este.calculateIndividualTitration(previoustitration);
      }
      if (titration){
        titrations.push(titration);
      }

      if (titration){
        var titrationDateOb = new Date(titration["tritrationdate"]);//newTitrationDate);
        //check if newTitrationDate is in the future and set nextfuturerefilltitration
        var now = new Date();
        if (nextfuturerefilltitration === false) {
          if (titrationDateOb>now){
            nextfuturerefilltitration=titration["tritrationdate"]//newTitrationDate;
          }
        }
      }
    }
    if (runs > window.maxruns) {
      titration=false;
    }
    if (titration){
      titration,titrations,titrationstoapi,modificators,nextfuturerefilltitration=this.titrationLoop(titration,titrations,titrationstoapi,modificators,nextfuturerefilltitration);
    }
    return titration,titrations,titrationstoapi,modificators,nextfuturerefilltitration;
  }

  this.calculateIndividualTitration= function(previoustitration,modificator,index){
    
      previoustitration=previoustitration||false;
      modificator=modificator|| false;
      index=index|| previoustitration.index+1;

      if (isNaN(index)){
        index=0;
      }

      //var titrationerrors=[];

      var mydrugs=this.alldrugs();
      var modified=false
      if (modificator){
        modified=true;
      }
      //If the date format is incorrect we will parse it into format Date
      //if(this.data.pumprefillday.__proto__.constructor.name != "Date"){
      if  (this.data.pumprefillday instanceof Date) {
        var pumprefillday = this.data.pumprefillday;
      } else {
        var pumprefillday = new Date(this.data.pumprefillday);
      }

      var dayssinceprevious = 15; //B10
      var dayssincelastdoseincrease = 15; //B10, B23, B36, B49, ETC
      var doseporcentagechange = 0.0; //G10
      var dayspatienttreated = 15; //L10
      var remainingvoltri=0;
      var dailydoseadministered=[];
      var previousparameters=this.previousparametersFunction();

      /* ESTO NO SE DE DONDE VIENE PERO ES INCORRECTO SEGUN EL EXCEL
      if (previoustitration) {
         dayssinceprevious=previoustitration["dayssinceprevious"];
      }
      */

      var indexm="";

      if (modificator){
        indexm=modificator.index
        dayssinceprevious = parseInt(modificator["dayssinceprevious"]);
        doseporcentagechange = parseFloat(modificator["doseporcentagechange"]);
        dayspatienttreated = parseInt(modificator["dayspatienttreated"]);
      }

      //G10
      doseporcentagechange = doseporcentagechange / 100.0; //adapt the amount FIXME: REVISAR ESTO
      //O6 => =IF($'Sync.II.MED. MIX&BOLUS Calc.'.BJ14=0,$'Sync.II.MED. MIX&BOLUS Calc.'.BB14,$'Sync.II.MED. MIX&BOLUS Calc.'.BJ14)
      //O6 => =IF(BJ14=0;BB14;BJ14)

      var volumeoriginalfill = 0.0
      if (this.volumeofeachnewdilution == 0) {
        volumeoriginalfill = this.data.refillamount;
      } else {
        volumeoriginalfill = this.volumeofeachnewdilution;
      }

      //Column H - Array de valores
      //D10*(1+G10) => D10 = BG7
      var drugconcentrationsArr = [];
      drugconcentrationsArr.push(this.drugconcentrations(this.data.primarydrug[0]));
      var este = this;
      this.data["otherdrugs"].forEach(function(d) {
        drugconcentrationsArr.push(este.drugconcentrations(d));
      });



      //El valor de doseporcentagechange es incorrecto FIXME:
      var parametersuntilnexttritration = [];
      parametersuntilnexttritration = drugconcentrationsArr.map(function(e) {

        //var shouldadd=0;
        //if (e>=1){
        var  shouldadd=1.0;
        //}
        return e * (shouldadd+ doseporcentagechange)
      });

      var continuousdailyflow=0.0;

      //Sólo queremos que muestre el valor inicial de titrationdate en el primer loop
      //Tambien queremos mostrar solo el valor inicial del array de newparametersuntilnexttitration en el primer loop que luego usaremos para los siguientes
      if (previoustitration) {

        continuousdailyflow=parseFloat(previoustitration["newcontinuousdailyflow"]);//continuousdailyflow
        var newcontinuousdailyflow = parseFloat(continuousdailyflow) * (1.0 + parseFloat(doseporcentagechange));
        //newcontinuousdailyflow=parseFloat(continuousdailyflow)*(1.0+parseFloat(doseporcentagechange));//previoustitration["newcontinuousdailyflow"];
        //titrations que ya no son la primera
        //C10-(I10*B23)
   
        remainingvoltri =previoustitration["remainingvolumeatend"];//previoustitration["remainingvolumeinthepump"]-(newcontinuousdailyflow*dayssinceprevious);
  
      } else {
        //primera titration
        //newcontinuousdailyflow=this.dailyvolumecontinuoslyinfusedroundeddilution();
        continuousdailyflow=parseFloat(this.dailyvolumecontinuoslyinfusedroundeddilution());

        var newcontinuousdailyflow = parseFloat(continuousdailyflow) * (1.0 + parseFloat(doseporcentagechange));
        //newcontinuousdailyflow=continuousdailyflow*(1.0+parseFloat(doseporcentagechange));


        //newTitrationDate = titrationDate;
        var newparametersuntilnexttritration = parametersuntilnexttritration;
        //C10 => O6-(B10*E10)
        remainingvoltri = volumeoriginalfill - (dayssinceprevious * this.dailyvolumecontinuoslyinfusedroundeddilution());

      }
  
      //I10 => E10*(1+G10)

      var newcontinuousdailyflow = parseFloat(continuousdailyflow) * (1.0 + parseFloat(doseporcentagechange));
      //M10 => C10-(I10*L10)
      var remainingvolumeatend = remainingvoltri - (newcontinuousdailyflow * dayspatienttreated);


      //D19 (columna D)
      var este=this;
   
      if (previoustitration) {
        //titrations que ya no son la primera

        dailydoseadministered=previoustitration["newparametersuntilnexttritration"];

        //newparametersuntilnexttritration=//previoustitration["newparametersuntilnexttritration"];
        //=IF(D23=0,D10*(1+$G$23),D23*(1+$G$23))

        newparametersuntilnexttritration=mydrugs.map(function(d,i) {
          if (dailydoseadministered[i]==0){
            //D10*(1+$G$23)
            return previoustitration["dailydose"][i]*(1+doseporcentagechange);
          } else {
            //D23*(1+$G$23)

            return previoustitration["newparametersuntilnexttritration"][i]*(1+doseporcentagechange);
          }
        });



        //if (dayssinceprevious){
        var titrationDateOb = new Date(previoustitration["tritrationdate"]);//newTitrationDate);
        titrationDate = titrationDateOb.addDays(previoustitration["dayssinceprevious"]).toISOString().substring(0, 10);
        //}
      } else {
        //first titration
         //valor inicial para el primer titration, para que luego se vaya modificando en el while

          var titrationDate=Date.now();
          if (dayssinceprevious){
            var titrationDate = pumprefillday.addDays(this.dayssinceprevious_default).toISOString().substring(0, 10);
          }
        mydrugs.forEach(function(d){
          dailydoseadministered.push(este.dailydoseofeachdrugtobeadministeredtothepatient(d));
        });
      }

      //resultados en limpio
      var previousparametersoutput=[];
      var newparametersuntilnexttitrationoutput=[];
      var este=this;
      mydrugs.forEach(function(d,i){

        var pdrug={};
        var ndrug={};
        pdrug["name"]=d.drug.name;
        pdrug["unit"]=d.drug.unitsingular;
        ndrug["name"]=d.drug.name;
        ndrug["unit"]=d.drug.unitsingular;
        pdrug["dailydoseadministered"]=este.round(dailydoseadministered[i],3);
        pdrug["pumpconcentrationremaining"]=este.round(previousparameters[i],3);
        previousparametersoutput.push(pdrug);

        ndrug["units"]=este.round(newparametersuntilnexttritration[i]);
        newparametersuntilnexttitrationoutput.push(ndrug);
      });

      var titrationapi={
        "dayssinceprevious":dayssinceprevious,
        "doseporcentagechange":(doseporcentagechange*100),
        "dayspatienttreated":dayspatienttreated,
        "index":index
      }


      //check for errors
      //=IF(M23<=0,"Insufficient volume. Impossible to carry out the treatment with the indicated dose"," ") => Este es el que ahora es generico, substituir por este

      //info alerts
      //=IF(SUM($'Sync.II.MED. MIX&BOLUS Calc.'.G8:$'Sync.II.MED. MIX&BOLUS Calc.'.G13)>0,"Unknown Stability",IF(($'Sync.II.MED. MIX&BOLUS Calc.'.$J$11)-($B$10+$B$23)<=0,"According to SmPC, it is not recommended to continue using the same refill due to stability"," "))

      var newtritratrion = {
        "index":index,
        "modified":modified,
        "tritrationdate": titrationDate,//newTitrationDate,
        "dayssinceprevious": dayssinceprevious,
        "remainingvolumeinthepump": remainingvoltri,
        "previousparameters": previousparameters,
        "doseporcentagechange": (doseporcentagechange*100),
        "newparametersuntilnexttritration": newparametersuntilnexttritration,
        "newcontinuousdailyflow": newcontinuousdailyflow,
        "dayspatienttreated": dayspatienttreated,
        "remainingvolumeatend": remainingvolumeatend,
        "continuousdailyflow":continuousdailyflow,
        "dailydose":dailydoseadministered,
        "previousparameters_output":previousparametersoutput,
        "newparametersuntilnexttitration_output":newparametersuntilnexttitrationoutput,
       //"errors":titrationerrors,XXXXXX
        "output_api":titrationapi
      }

      newparametersuntilnexttritration = newparametersuntilnexttritration.map(function(e,i) {
        var resultado =0.0;
        if (i==0){
          resultado=e * (1 + doseporcentagechange);
        } else {
          resultado=e;
        }
        return resultado;
      });

      if (remainingvolumeatend>this.requiredpumpsurplus){
        return newtritratrion;
      } else {
        //NO PASSSSS ...........
      }

      return false;
  }

  this.defaultAddtitrationvalues=function(){
    return  {
      dayssinceprevious:15,
      doseporcentagechange:-100,
      dayspatienttreated:15
    };

  }

  this.addTitration=function(titrations,newTitration){

    newTitration=newTitration||this.defaultAddtitrationvalues();
  
    var trits=titrations;
    var titrationstoapi=[];
    var newtitrationindex=titrations.length;

    for (var i = 0; i < titrations.length; i++) {
      //trits.push(titrations[i]);
      if (titrations[i].modified){
        titrationstoapi.push(titrations[i]["output_api"]);
      }
    }
    
    //trits.push(newTitration);
    var lasttitration=titrations[titrations.length -1 ];

    var newtitration=this.calculateIndividualTitration(lasttitration,newTitration[0],newtitrationindex);
   
    var newT=newtitration;
    if (newtitration){
      newtitration.modified=true;
      newtitration.index=newtitrationindex;
      newT.modified=true;
      trits.push(newtitration);
      titrationstoapi.push(newtitration["output_api"]);
    } else {
      newT=false;
    }

    var data={"titrations":trits,"passed":newT,"titrations_to_API":titrationstoapi};

    return data;

  }

  this.modifyTritration = function(input, recharge, modifications) {
    //returns list of tritratrions (since modifing one will affect the next ones)
    var trits = this.tritrationCalculator(input, recharge, modifications);
    return trits;
  }

  this.calculateAll = function() {}

  this.getDosePercentageChangeOptions = function(){
    var options=[
      0.0,
      0.5,
      1.0,
      2.0,
      5.0,
      10.0,
      15.0,
      25.0,
      40.0,
      50.0,
      75.0,
      100.0,
      150.0,
      200.0,
      300.0,
      -10.0,
      -25.0,
      -50.0,
      -90.0,
      -100.0
    ];
    return options;
  }

  this.errorTitrationCalc=function(){
    return "Insufficient volume. Impossible to carry out the treatment with the indicated dose.";
  }

  this.round=function(num,decimals){
    decimals=decimals|| 2;
    //Math.round((num + Number.EPSILON) * 100) / 100
    if (num) {
      num=parseFloat(num);
      //return Math.floor((Math.pow(10, 2)*num)+0.5)*Math.pow(10, -2);
      return parseFloat(num.toFixed(decimals));
    } else {
      return 0.00;
    }
  }
}
