import { Calculator } from "./layouts/CalculatorLayout/Calculator";

export const waitForDomElement = async selector => {
    while ( document.querySelector(selector) === null) {
      await new Promise( resolve =>  requestAnimationFrame(resolve) )
    }
    return document.querySelector(selector); 
  };

const waitForDomElement2 = async selector => {
    while ( document.querySelector(selector) === null) {
      await new Promise( resolve =>  requestAnimationFrame(resolve) )
    }
    return document.querySelector(selector); 
  };

export const convertPumpIdtoPumpObject=function(pumpid,pumps){
    var found={};

    pumps.forEach((p) => {
        if (p.id==pumpid){
            found=p;
        }
    });

    return found;
}

export const assignErrorText=function(error){
    var text=error.message;
    var toreturn=text;
    switch(text){
        case "Cannot read properties of undefined (reading 'drug')":
            toreturn="A primary drug is required.";
            break;
    }
    return toreturn;
}


export const defaultAddtitrationvalues=function(input, pump ,pumpcapacity){
    var calculo = new Calculator(input, pump ,pumpcapacity);
    return  calculo.defaultAddtitrationvalues();
}

export const getDosePercentageChangeOptions=function(input, pump ,pumpcapacity){
    var calculo = new Calculator(input, pump ,pumpcapacity);
    return calculo.getDosePercentageChangeOptions();
}

export const errorTitrationCalc=function(input, pump ,pumpcapacity){
    var calculo = new Calculator(input, pump ,pumpcapacity);
}

export const calculateTitrations=function(input,pump ,pumpcapacity,trirationmodifications=[],titrationtoADD=[]){
   
    var calculo = new Calculator(input, pump ,pumpcapacity);

    var calc_recharge = calculo.calculateRecharge();
   
    if ((trirationmodifications.length+titrationtoADD.length)==0){
      
    }
    
    var trits = calculo.tritrationCalculator(input, calc_recharge);
    if (trirationmodifications.length){
        var tritsmodified = calculo.modifyTritration(input, calc_recharge, trirationmodifications);
        if (titrationtoADD.length==0){
           
            return tritsmodified;
        }
    }
    if (titrationtoADD.length>0){
        var titrationsfinal = calculo.addTitration(tritsmodified["titrations"], titrationtoADD);
       
        return titrationsfinal;
    }
    return  calc_recharge;
}

export const processModifiedTitrations=function(formValue,modifiedTitrations){
    var mT=[];
    let alreadyincluded=false;
    for (var i = 0; i < modifiedTitrations.length; i++) {
       
        if (modifiedTitrations[i].index==formValue.index){
            mT.push(formValue);
            alreadyincluded=true;
        } else {
            mT.push(modifiedTitrations[i]);
        }
    }
    if (!alreadyincluded){
       
        mT.push(formValue);
    }
    
    return mT;
}

export const formBackFixer=function(formValues){
  
    if (formValues){
       
        //primary drug :::::

        //dailydose
        waitForDomElement2(".add-recharge-form #primary-drugs .input-drug input").then((selector) => {
            let dailydose=selector.value;
            let formValue=formValues.primarydrug[0].dailydose;
            if (dailydose==""){
                selector.value=formValue;
            }
        });

        waitForDomElement2(".add-recharge-form #primary-drugs .input-drug .selection > div").then((selector) => {
            let concentration=selector.innerHTML;
          
            let formValue=formValues.primarydrug[0].concentration;
            if (concentration=="Concentration"){
                selector.innerHTML=formValue;
                selector.classList.remove("default");
                //selector.value=formValue;
            }
        });

        //secondary drug :::::
        if (formValues.otherdrugs){
            formValues.otherdrugs.map((d,i) =>{
                waitForDomElement2(".add-recharge-form #secondary-drugs .input-drug:nth-child("+(i+1)+") input").then((selector) => {
                    let dailydose=selector.value;
                    let formValue=d.dailydose;
                
                    if (dailydose==""){
                        selector.value=formValue;
                    }
                });

                waitForDomElement2(".add-recharge-form #secondary-drugs .input-drug:nth-child("+(i+1)+") .selection > div").then((selector) => {
                    let concentration=selector.innerHTML;
                    let formValue=d.concentration;
                    if (concentration=="Concentration"){
                        selector.innerHTML=formValue;
                        selector.classList.remove("default");
                        //selector.value=formValue;
                    }
                });
            });
        }
    }
}