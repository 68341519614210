import React from 'react';
import { Link } from 'react-router-dom';


export function SingleRecharge(props) {
    const { recharge, titrationsprocessed,patient, past } = props;
    
    var description="Ongoing Since";
    if (props.past==1){
        description="Refilled";
    }
    var rechargestart_date=new Date(recharge.pumprefillday);
    var titration_date=rechargestart_date;


    if(past == 0){
        return (
            <Link className="ui card" to={`/edit-recharge/${recharge.id}`} state={{ recharge: recharge, pump:patient.pump, past: past, patient: patient.id }} key={recharge.id}>
               
                <div className="flex-space content">
                    <div className="field">
                        <div className="label">{description}</div>
                        <div className="value">{recharge.pumprefillday}</div>
                    </div>
                    <div className="field right">
                        <div className="label">#titrations</div>
                        <div className="value">
                            { titrationsprocessed.length ? titrationsprocessed.map((element, i) => {
                            var dotclass="dot ";
                            titration_date=titration_date.addDays(element["dayssinceprevious"]);
                            if (titration_date<Date.now()){
                                dotclass="dot past";
                            }
                          
                            return (<span className={dotclass} key={i}>•</span>)
                            }):(<></>)}
                        </div>
                    </div>
                </div>
                
            </Link>
        ) 
    } else {
        return (
            <Link className="ui card" to={`/edit-recharge/${recharge.id}`} state={{ recharge: recharge, pump:patient.pump, past: past }} key={recharge.id}>
               
                <div className="flex-space content">
                    <div className="field">
                        <div className="label">{description}</div>
                        <div className="value">{recharge.pumprefillday}</div>
                    </div>
                    <div className="field right">
                        <div className="label">#titrations</div>
                        <div className="value">{recharge.tritrations.map((element, i) => {
                            return (<span className="dot past" key={i}>•</span>)
                        })}
                        </div>
                    </div>
                </div>
                
            </Link>
        )
    }
    
}
