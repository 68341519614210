import { ClientLayout } from "../layouts";
import { Login, Patients, AddPatient, EditPatient, AddRecharge, EditRecharge, Logout, PatientContent } from "../pages/Client";
import { Iframe } from "../components/Client/Iframe";

const routesClient = [
    {
        path: "/login",
        title:"Login",
        id:"login",
        layout: ClientLayout,
        component: Login,
        exact: true,
        isiframe: false
    },
    {
        path: "/",
        title:"Patients",
        id:"patients",
        layout: ClientLayout,
        component: Patients,
        exact: true,
        isiframe: false
    },
    {
        path: "/add-patient",
        title: "New Patient",
        id:"addpatient",
        layout: ClientLayout,
        component: AddPatient,
        exact: true,
        isiframe: false
    },
    {
        path: "/edit-patient/:id",
        title: "Patient profile",
        id: "editpatient",
        layout: ClientLayout,
        component: EditPatient,
        exact: true,
        isiframe: false
    },
    {
        path: "/patient/:id",
        title: "Patient profile",
        id:"patientprofile",
        layout: ClientLayout,
        component: PatientContent,
        exact: true,
        isiframe: false
    },
    {
        path: "/add-recharge",
        title: "Add recharge",
        id:"addrecharge",
        layout: ClientLayout,
        component: AddRecharge,
        exact: true,
        isiframe: false
    },
    {
        path: "/edit-recharge/:id",
        title: "Edit recharge",
        id:"editrecharge",
        layout: ClientLayout,
        component: EditRecharge,
        exact: true,
        isiframe: false
    },
    {
        path: "/logout",
        title: "Logout",
        id: "logout",
        layout: ClientLayout,
        component: Logout,
        exact: true,
        isiframe: false
    },
    {
        path: "admin",
        title: "Admin",
        id:"admin",
        layout: ClientLayout,
        component: Iframe,
        exact: true,
        isiframe: true
    },
    {
        path: "support",
        title: "Support",
        id:"support",
        layout: ClientLayout,
        component: Iframe,
        exact: true,
        isiframe: true
    },
    {
        path: "profile",
        title: "Profile",
        id:"profile",
        layout: ClientLayout,
        component: Iframe,
        exact: true,
        isiframe: true
    },
    //legal iframed
    {
        path: "legal/cookies",
        title: "Cookies policy",
        id:"cookies",
        layout: ClientLayout,
        component: Iframe,
        exact: true,
        isiframe: true
    },
    {
        path: "legal/privacy",
        title: "Privacy policy",
        id:"privacy",
        layout: ClientLayout,
        component: Iframe,
        exact: true,
        isiframe: true
    },
    {
        path: "legal/notice",
        title: "Legal notice",
        id:"notice",
        layout: ClientLayout,
        component: Iframe,
        exact: true,
        isiframe: true
    }

    
];



export default routesClient;