import React, { useEffect } from "react";
import {Loader, Button} from "semantic-ui-react";
import { useAuth, usePatient } from "../../hooks";

import { ListPatients } from "../../components/Client";

export function Patients() {
    const { logout, auth } = useAuth();  
    const { loading, patients, getPatients } = usePatient();

    useEffect(() => getPatients(), []);

    return (
        <>
            {loading ? (
                <Loader active inline="centered">
                    Loading...
                </Loader>
            ) : (
                <div>
                    <ListPatients patients={patients}/>
                    {/* <Button onClick={logout} content="Cerrar sesión" secondary fluid /> */}
                </div>
            )}
            
        </>
    )
}
