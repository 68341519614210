import React, { useEffect, useState } from "react";
import {Loader, Button} from "semantic-ui-react";
import { useAuth, usePatient } from "../../hooks";
import {useLocation} from "react-router-dom";
import { ModalBasic } from "../../components/Client/Common"
import { EditRechargeForm } from "../../components/Client";
import { useParams } from 'react-router-dom';

export function EditRecharge(props) {
    const { loading, logout, auth, assignStep } = useAuth();  

    let data = useLocation();

 

    return (
        <>
            {loading ? (
                <Loader active inline="centered">
                    Loading...
                </Loader>
            ) : (
                <div>
                    <EditRechargeForm props={data} past={data.state.past}/>
                </div>
            )}
            
        </>
    )
}