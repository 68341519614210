import React from 'react';

export function SecondNext(props) {

  return (
    <div>
      <div className="list">
        <div className="ui card">
          <div className="content">
            <h3>Dilutions for a complete refill</h3>
            <div className="fieldpair">
            <div className="field fd">
              <div className="flex-space">
                <div className="flex-element label">
                  Final volume required to fill the pump completely with the proposed dilution
                </div>
                <div className="flex-element value">
                  {props.calculoData ? props.calculoData.finalvolume : null}
                  <span className="unit">ml</span>
                </div>
              </div>
            </div>
            <div className="field fd">
              <div className="flex-space">
                <div className="flex-element label">
                  Total pump volume
                </div>
                <div className="flex-element value">
                  {props.formValues ? props.formValues.pumpcapacity : null}
                  <span className="unit">ml</span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

        {props.formValues ?
          (<div className="ui card">
            <div className="content">
              <h3>Volumes required</h3>
              <div className="fieldlist">
              {props.calculoData.volumesrequired_output.map((item, index) => {
                return (
                  <div className="field" key={index}>
                  <div className="flex-space">
                    <div className=" label">
                      {item.name}
                    </div>
                    <div className=" value text-right">
                      {item.units.toFixed(1)}
                      <span className="unit">{item.unitsingular}</span>
                    </div>
                  </div>
                </div>
                );
              })}

              

              <div className="field">
                <div className="flex-space">
                  <div className="flex-element label">
                    Volume of "NaCl 9 mg/ml(0,9 %)" required for the new dilution and for new total volume
                  </div>
                  <div className="flex-element value text-right">
                    {props.calculoData.volumeofnacl.toFixed(1)}
                    <span className="unit">ml</span>
                  </div>
                </div>
              </div>



            </div>
            </div>
          </div>

          ) : null}


        {props.formValues ?
          (<div className="ui card importantcard">
            <div className="content">
              <h3>Remember</h3>
              <div className="fieldlist rememberlist">
              {props.calculoData.remember_output.map((item, index) => {
                return (
                  <div className="field" key={index}>
                    <h4>{item.name} </h4>

                    <div className="field">
                      <div className="flex-space">
                        <div className="label">
                          Daily dose
                        </div>
                        <div className="value text-right">
                          {item.dailydose.toFixed(3)}<span className="unit">{item.unitsingular}</span>
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <div className="flex-space">
                        <div className="label">
                          Real final concentration
                        </div>
                        <div className="value text-right">
                          {item.realfinalconcentration.toFixed(3)}<span className="unit">{item.unitsingular}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              
            </div>
            </div>
          </div>
          )
          : null}

        {props.formValues ?
          (<div className="ui card">
            <div className="content">
              <h3>Final surplus at next refill</h3>
              <div className="field">
                <div className="flex-space">
                  <div className="label">
                    Surplus in pump before next appointment (without a refill or titration)
                  </div>
                  <div className="value text-right">
                    {props.calculoData ? props.calculoData.finalsurplus.toFixed(2) : null}
                    <span className="unit">ml</span>
                  </div>
                </div>
              </div>
            </div></div>
          )
          : null}

      </div>
    </div>
  )
}
