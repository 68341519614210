import React, { useState } from 'react';


export function ThirdNext(props) {

    function onClick(data, index) {
        props.parentCallback(data, index);   
    }



    return (
        <div className="list">
            { props.titrations ? (
                <>
                    <h3>Suggestion for titrations</h3>
                    {props.titrations.map((item, index) => {

                        return (
                            <a key={index} className={item.modified ? ("tritation ui card modified") : ("tritation ui card") } onClick={() => onClick(item, index)}>
                                <div className="content">
                                    <div className="flex-space">
                                        <h4>Titration #{index + 1}</h4>
                                        <div><span className="label statetit">{item.modified ? (<>DONE</>) : (<>SUGGESTED</>) }</span></div>
                                    </div>
                                <div className="field">
                                    <div className='date'>
                                        <div className='label'>Tritation date</div>
                                        <div className='value'>{ item.tritrationdate }</div>
                                    </div>
                                </div>
                                </div>
                            </a>
                        )
                    })}
                </>         
            ) : null }
        </div>
    )
}
