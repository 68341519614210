import { BASE_API } from "../utils/constants";

export async function getPatientsAPI(token){
    try {
        const url = `${BASE_API}api/patients/`;
        const params = {
            headers:{
                Authorization: `Token ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json();
        localStorage.setItem('patients', JSON.stringify(result));
        return result;

    } catch(error) {
        if(localStorage.getItem('patients')){
            const result = localStorage.getItem('patients');
            return result;
        } else {
            throw error;
        } 
    }
}

export async function addPatientAPI(data, token){
    try {
        const url = `${BASE_API}api/patients/`;
        const params = {
            method: "POST",
            headers:{
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        } 
        const response = await fetch(url, params);
        const result = await response.json();
        return result;

    } catch (error) {
        var existingEntries = JSON.parse(localStorage.getItem("allAddedPatients"));
        localStorage.setItem("addedPatient", JSON.stringify(data));
        existingEntries.push(data);
        localStorage.setItem("allAddedPatients", JSON.stringify(existingEntries));
        var localSave = true;
        return localSave;
    }
}

export async function getPatientAPI(id, token){
    try {
        const url = `${BASE_API}api/patients/${id}/`;
        const params = {
            headers:{
                Authorization: `Token ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json();
        return result;

    } catch(error) {
        if(localStorage.getItem('patients')){
            const result = localStorage.getItem('patients').find(item => item.id === id);
            return result;
        } else {
            throw error;
        }
    }
}

export async function editPatientAPI(id,data,token){
    try {
        const url = `${BASE_API}api/patients/${id}/`;
        const params = {
            method: "PUT", //IMPORTANTE PARA CUANDO EDITAMOS (diferente de cuando guardamos uno nuevo)
            headers:{
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        }

        const response = await fetch(url, params);
        const result = await response.json();
       
        return result;

    } catch(error) {
        var existingEntries = localStorage.getItem('patients').find(item => item.id === id);

        if(existingEntries){
            localStorage.setItem("patients", JSON.stringify(data)).find(item => item.id === rid);
            var localEditSave = true;
            return localEditSave;
        } else {
            throw error;
        }
    }
}
