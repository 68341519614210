import React, { useState} from 'react';
import { useAuth } from '../../../hooks';
import { Loader } from "semantic-ui-react";
import { Offline } from "react-detect-offline";
import './Iframe.scss';

export function Iframe(props) {
 

  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();  

  const hideLoader = () => {
    
    setLoading(false);
    document.getElementById("iframe").classList.remove("hidden");
  }

  const url = global.config.domain+props.path+'/?token='+auth.token;


  return (
    <>
      <Offline>
        <div className="offline_card">
        You must go online to view this page        
        </div>
      </Offline>
    
      {loading ? (
        <Loader active inline="centered">
        
        </Loader>
      ) : null}
          <iframe className="iframe hidden" id="iframe" onLoad={hideLoader} src={url} scrolling="no" sandbox='allow-downloads allow-scripts allow-top-navigation allow-forms allow-popups allow-same-origin allow-presentation'/> 
      
    </>  
  );
}
