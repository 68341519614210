import React, { useState, useEffect } from 'react';
import { useDrug, useRecharge,usePump } from '../../../../hooks';
import {SingleRecharge, SingleRechargeCurrent } from '../../../../components/Client';
import { Calculator } from "../../../../layouts/CalculatorLayout/Calculator";
import map from 'lodash';
import "./SinglePatient.scss";
import * as helpers from '../../../../helpers.js';


export function SinglePatient(props) {

    const { recharges, patient } = props;
    const { pump, getSinglePump } = usePump();
    const { editRecharge, getRecharge, toList, setToList } = useRecharge();

    const [ nextrefilldate, setNextrefilldate ] = useState(" ");
   // var nextrefilldate="";
    useEffect(() => getSinglePump(props.patient.pump), []);
    useEffect(() => {
        var output="EXPIRED"
     
        if (patient.pump){
            if (recharges.length){
                //alert("pump ready!");
             
                var  titrations=helpers.calculateTitrations(recharges[0], patient.pumpobject,patient.pumpcapacity,[null]);
                output=titrations["nextfuturerefilltitration"];
                if (!output){
                    output="EXPIRED"
                }
            }
        }
        setNextrefilldate(output);
    });
    

    //useEffect(() => getSinglePump(props.patient.pump), []);

    if(Object.keys(recharges).length > 0){
     
        return (
            <>
            { patient.pump ? (
            <div>
                <h3>Current Recharge</h3>
                <div className="list">
                {recharges.map((element, i) => {
                    
                    if(i == 0){

                        //calculate titrations with modifications
                        let titrationsProcesed=[];
                        var calculadora = new Calculator(element, patient.pumpobject,patient.pumpcapacity);
                        var calculo=calculadora.calculateRecharge();
                        var tritations =calculadora.modifyTritration(element, calculo, element.tritrations);
                      
                        return ( <SingleRecharge recharge={element} titrationsprocessed={tritations.titrations} patient={patient} key={element.id} past={false} />)
                    } else {
                        return null
                    }

                })}
                {recharges.map((element, i) => {
                    if(i == 0){
                       return( <div className="label" key={element.id}>Next refill: <b>{nextrefilldate}</b></div>)
                    } else {
                        return null
                    }
                })}
                </div>
                <h3>Previous recharges</h3>
                <div className="list">
                {recharges.map((element, i) => {
                    if(i == 0){

                    } else {
                        let titrationsProcesed=[];
                 
                        var  tritations=helpers.calculateTitrations(element, patient.pumpobject,patient.pumpcapacity,[null]);
                        return ( <SingleRecharge recharge={element} titrationsprocessed={tritations.titrations} patient={patient} key={element.id} past={true} />)
                    }
                })}
                </div>
            </div>
            
            ):(<></>)}
            </>
        )
        
    } else {
        return (
            <div className="ui card fullcard">
            <div className="content">
                <div className="message">You haven't added any recharge yet</div>
                </div>
            </div>
        )
    }
}

function CalculateDate(recharge){

    var date = new Date(recharge.pumprefillday);
    var datedef = new Date(recharge.recharges[0].pumprefillday);
    var dias = recharge.daysuntilnextrefill;
    datedef.setDate(date.getDate() + dias);

    return{
        date: date,
        datedef: datedef
    }
}
