import React, {useState, useEffect, createContext } from "react";
import { setToken, getToken, removeToken } from "../api/token";
import { useUser } from "../hooks";


export const AuthContext = createContext({
    auth: undefined,
    login: () => null,
    logout: () => null,
    formstep:undefined
});


export function AuthProvider(props){
    const { children } = props; 
    const [ auth, setAuth ] = useState(undefined);
    const { getMe } = useUser();
    var [formstep,setStep ] = useState(1);

    useEffect(() => {
       
        (async () => {
            const token = getToken();
            
            if(token){
                const me = await getMe(token);
                setAuth({ token, me });
            } else {
                setAuth(null);
            }
        })();
    }, []);



    const login = async (token) => {
        setToken(token);
        const me = await getMe(token);
      
        setAuth({ token, me });
    }

    const logout = () => {
        if(auth){
            removeToken();
            setAuth(null);
        }
    }

    const assignStep = (step) => {
        setStep(step);
        //setStep(current=>current+step );
        //formstep=step;
  
    }

    const valueContext = {
        auth,
        login,
        logout,
        formstep,
        assignStep
    }

    if(auth === undefined) return null;

    return (
        <AuthContext.Provider value={ valueContext }>
            { children }
        </AuthContext.Provider>
    )

}

