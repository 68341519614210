import React,{ useState, useEffect } from 'react';
import { Form, Button, Checkbox, Select, TextArea, Modal } from "semantic-ui-react";
import { map, set } from "lodash";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Navigate } from 'react-router-dom';
import { useDrug, useRecharge, usePump,useAuth,usePatient } from '../../../../hooks';
import { ModalBasic,ModalErrors,ConfirmEditRecharge } from "../../../../components/Client";

import { SecondNext, ThirdNext, LastNext } from "./components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link , Redirect} from 'react-router-dom';
import * as helpers from '../../../../helpers.js';
const initialList = [];

window.useddrugs=[];

window.initsetsueddrugs=false;

function removeUsedDrug(drug){
    drug=parseInt(drug);
    for( var i = 0; i < window.useddrugs.length; i++){ 
        if ( window.useddrugs[i] === drug) { 
            window.useddrugs.splice(i, 1); 
        }
    };
}



export function EditRechargeForm(props) {
    
    const { formstep,assignStep } = useAuth();
    const { drugs, drugsPrimary, drugsSecondary, getDrugs, getDrugsPrimary, getDrugsSecondary } = useDrug();
    const { pump, getSinglePump } = usePump();
    const {patient,getPatient} = usePatient();
    const { editRecharge,getRecharge, toList, setToList } = useRecharge();

    // OTRAS VARIABLES QUE YA FUNCIONAN
    const [startDate, setStartDate] = useState(new Date());
    const [showModal, setShowModal] = useState(false);
    const [showModalPrim, setShowModalPrim] = useState(false);
    const [showTitration, setShowTitration] = useState(null);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [drugsFormat, setDrugsFormat] = useState([]);
    const [drugsPrimaryFormat, setDrugsPrimaryFormat] = useState([]);
    const [inputPrim, setInputPrim] = useState(null);
    const [inputData, setInputData] = useState(null);
    const [inputDrug, setInputDrug] = useState(null);
    const [list, setList] = useState(null);
    const [listSecondary, setListSecondary] = useState([]);
    const [pumpFormat, setPumpFormat] = useState([]);
    const [showResults, setShowResults] = useState(true);
    const [showResultsDrugs, setShowResultsDrugs] = useState(false);
    const [concentrationFormat, setConcentrationFormat] = useState([]);
    const [concentrationPrimFormat, setConcentrationPrimFormat] = useState(null);
    const [inputDataForm, setInputDataForm] = useState([]);
    const [toNext, setToNext] = useState(false);
    const [toNextLast, setToNextLast] = useState(false);
    const [calculoData, setCalculoData] = useState(null);
    const [defaultPerc, setDefaultPerc] = useState(null);
    const [showPrimButton, setShowPrimButton ] = useState(false);
    const [concentrationPrim, setConcentrationPrim] = useState(null);
    const [concentrationList, setConcentrationList] = useState([]);
    const [initial, setInitial] = useState(true);
    const [percentages, setPercentages] = useState([])
    const [formatPercentage, setFormatPercentage] = useState([]);
    const [indexTitration, setIndexTitration] = useState(null);
    // FIN VARIABLES QUE YA FUNCIONAN

    // VARIABLE QUE ASIGNA SI HEMOS MODIFICADO ALGO O NO EN EL PRIMER PASO
    const [ismodified, setismodified] = useState(false)

    // FORMVALUES TOTAL
    const [formValues, setFormValues] = useState(null);

    // LISTA DE TITRATIONS
    const [titrations, setTitrations] = useState(null);

    // TITRATION QUE SE MODIFICA
    const [singleTitration, setSingleTitration] = useState(null);

    // TITRATION NUEVA
    const [newTitration, setNewTitration] = useState(false);

    // ESPECIFICA SI DEBE EJECUTARSE MODIFICAR O AÑADIR TITRATION AL CAMBIAR EL VALOR EN EL FORM
    const [modify, setModify] = useState(null);
    const [add, setAdd] = useState(null);

    // TITRATIONS MODIFICADAS
    const [modifiedTitrations,setmodifiedTitrations]= useState([]);

    //Confirm modals
    const [showConfirmRecharge, setShowConfirmRecharge] = useState(false);
    const cancelConfirmRecharge = () => setShowConfirmRecharge(false);
    const confirmConfirmRecharge = () => submitFirstStepRecharge();
    const [doConfirmRecharge, onConfirmRecharge] = useState(null);
    const [errormessage, setShowModalErrorText] = useState("");
    const [showModalError, setShowModalError] = useState(false);

    //confirm modal titration
    const [showConfirmTitration, setShowConfirmTitration] = useState(false);
    const cancelConfirmTitration = () => setShowConfirmTitration(false);
    const confirmConfirmTitration = () => submitModifyTitration();
    const [doConfirmTitration, onConfirmTitration] = useState(null);
    
    //navigation: manages step navigation and show/hide each element
    useEffect(() => controlNavigation());

    const [actualRecharge, setActualRecharge] = useState(null);
    
    const openCloseModal = () => setShowModal((prev) => !prev);
    const openCloseModalPrimary = () => setShowModalPrim((prevPrim) => !prevPrim);
    const openCloseButtonPrimary = () => setShowPrimButton((prevPrim) => !prevPrim);

    // USE EFFECTS.

    useEffect(() => getSinglePump(props.props.state.pump), []);
    useEffect(() => getPatient(parseInt(props.props.state.patient)), []);
    useEffect(() => setTimeout(function(){setPumpFormat(formatSelectDataPump(pump))}, 1000), [pump]);
    useEffect(() => {
        formik.setFieldValue('otherdrugs', listSecondary);
    }, [listSecondary]);
    useEffect(() => setConcentrationPrimFormat(formatSelectDataConcPrim(inputData)), [inputData]);
    useEffect(() => {
        return formik.setFieldValue('primarydrug', inputDataForm);
    }, [inputDataForm]);
    useEffect(() => {
        return formik.setFieldValue('patient', props.props.state.patientid);
    }, []);
    useEffect(() => {
        if(list){
            setConcentrationFormat(formatSelectDataConc(list));
        }    
    }, [list]);
    useEffect(() => {
        window.useddrugs=[];
    }, []);
    useEffect(() => {
        var rechargeVar = props.props.state.recharge;
     
        setmodifiedTitrations(rechargeVar["tritrations"]);
        // convert day to date
        rechargeVar['pumprefillday'] = new Date(rechargeVar.pumprefillday);
        var concentration = parseFloat(rechargeVar['primarydrug'][0].concentration).toFixed(1);
        var concentrationString = String(concentration);
        concentrationString = concentrationString.replace(',', '.')
        rechargeVar['primarydrug'][0].concentration = concentrationString;
        
        setActualRecharge(rechargeVar); 
    
       

        // otherdrugs set
        var arrayOther = rechargeVar['otherdrugs'];
        setList(rechargeVar['otherdrugs']);
        

        arrayOther.map(function (item) {
            var concentrationsList = parseFloat(item.concentration).toFixed(1);
 
            var concentrationListString = String(concentrationsList);
            concentrationListString = concentrationListString.replace(',', '.')  
       
            item.concentration = concentrationListString;
            window.useddrugs.push(item.drug.id);
        });

        setListSecondary(arrayOther);

        rechargeVar['primarydrug'][0].dailydose = String(rechargeVar['primarydrug'][0].dailydose);
        // primary drug set
        setInputPrim(rechargeVar['primarydrug'][0].drug)
        setInputData(rechargeVar['primarydrug'][0].drug.concentrations)
        setInputDataForm(rechargeVar['primarydrug'][0])
        

        setConcentrationPrim(concentrationString)
        window.useddrugs.push(rechargeVar['primarydrug'][0].drug.id);
        setShowModalPrim(false);
        setShowModal(false);

        // setValues general
        formik.setValues(rechargeVar);

        window.titrationsAPI = rechargeVar['tritrations'];
        
    }, [initial]);
    useEffect(() => getDrugsSecondary(), []);
    useEffect(() => getDrugsPrimary(), []);
    useEffect(() => setDrugsFormat(formatSelectData(drugsSecondary)), [drugsSecondary]);
    useEffect(() => setDrugsPrimaryFormat(formatSelectDataPrim(drugsPrimary)), [drugsPrimary]);

    
    // SALVADO FINAL DE TODO EL PROCESO DE CALCULO COMPLETO.

    const formikSave = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) =>{
            try {
             
               
                var finalValues = formValues;
                //finalValues['tritrations'] = formValues.tritrations.titrations;
                finalValues["tritrations"]=window.titrationsAPI;
                if (!finalValues["tritrations"]){
                    finalValues["tritrations"] = []
                }
                finalValues['pumprefillday'] = convertDate(formValues['pumprefillday']);
                finalValues['refillamount'] = parseInt(finalValues['refillamount']);
             
                if (props.past){
                    assignStep(0);
                    setToList(false);
                } else {
                    await editRecharge(finalValues);
                    setToList(false);
                }
             
                

            } catch (error) {
               throw error;
           }
        }
    });

    // CONTROLADOR DE NAVEGACION

    const controlNavigation= function(){
       
      
        var step=formstep;
        if (step==0){
            window.history.go(-1);
        }
        if (step==1){
            setToNext(false);
            helpers.formBackFixer(formValues);
           
        }
        if (step==2){
            
            setToNext(true);
            setShowConfirmRecharge(false);
            setToNextLast(false);
        }
        if (step==3){
            setShowModalError(false);
            setToNextLast(true);
            setShowTitration(false);
            setSingleTitration(null);
            setNewTitration(null)
        }
        if (step==4){
            setShowTitration(true);
        }
    }

    //FUNCION QUE SE EJECUTA CUANDO MODIFICAMOS CUALQUIER VALOR DE RECHARGE FORM
    const handleChangeRecharge = (event, data) => {
        setismodified(true);
   
    }


    //FORMULARIO DE MODIFY TITRATION

    const formikTitration = useFormik({
        initialValues: initialValuesTitrations(),
        validationSchema: Yup.object(newSchemaTitrations()),
        validateOnChange: false,
        onSubmit: async (formValue) =>{
          
          window.modfifTitrationvalues=formValue;
          
          if (props.past){
              submitModifyTitration();
          } else {
              setShowConfirmTitration(true);
          }
           
        }
    });

    const submitModifyTitration=function(){
       
        assignStep(3);
        let formValue=window.modfifTitrationvalues;
        
        try {
            var T=helpers.calculateTitrations(
                formValues, pump, patient.pumpcapacity,modifiedTitrations);

            var tritationsDef = T["titrations"];
            window.titrationsAPI=T["titrations_to_API"];
          
            setTitrations(tritationsDef);
          
            var finalValues = formValues;
            var islastTitration=false;
            if (formValue.index == tritationsDef[tritationsDef.length -1].index){
                islastTitration=true;
            }
            if (islastTitration && (tritationsDef.length < formValues.tritrations.length)){
               
                setShowModalErrorText('No titrations generated.')
                setShowModalError(true);
                
            } else {
               
                setSingleTitration(tritationsDef[singleTitration.index]);
            }
            console.log(formValues);
            finalValues['tritrations'] = tritationsDef;
            setFormValues(finalValues);
            console.log(finalValues);
          } catch (error) {
              throw error;
         }
         setSingleTitration(null);
         setShowConfirmTitration(false);
    }

    // EJECUTA CALCULADORA AL CAMBIAR VALORES EN MODIFY TITRATION

    const changeModifyTitration=function(formValue){ 
        console.log("modifing titration");
        try {
     
            var formDef = formValue;
            formDef['dayspatienttreated'] = parseInt(formValue['dayspatienttreated']);
            formDef['dayssinceprevious'] = parseInt(formValue['dayssinceprevious']);

            var mT=helpers.processModifiedTitrations(formDef,modifiedTitrations);
            setmodifiedTitrations(mT);
            
            var T=helpers.calculateTitrations(
                formValues, pump, patient.pumpcapacity,mT);

            console.log(T);
            console.log( formValues);
            console.log(formValue);
         

            var tritationsDef = T["titrations"];
            var islastTitration=false;
            if (tritationsDef.length){
                if (formValue.index == tritationsDef[tritationsDef.length -1].index){
                    islastTitration=true;
                }
            }
              // console.log( tritationsDef.length, formValues.tritrations.titrations.length);
               //console.log(formValues);
            if (islastTitration && (tritationsDef.length <  formValues.tritrations.length) || tritationsDef.length==0){
               
                setShowModalError(true);
                setShowModalErrorText("Insufficient volume. Impossible to carry out the treatment with the indicated dose."); 
            } else {
               
                window.titrationsAPI=T["titrations_to_API"];
                setSingleTitration(tritationsDef[singleTitration.index]);
            }
            
          } catch (error) {
              //alert(error.message)
              throw error;
         }
         
    }

    if(modify){
        changeModifyTitration(formikTitration.values);
        setModify(false);
    }

    //AQUI se checkean los datos de titration form

    const handleChangePercentage = (event, data) => {
        formikTitration.setFieldValue('doseporcentagechange', data.value );
        setModify(true);
    }

    const handleChangeDays = (event, data) => {
        formikTitration.setFieldValue('dayssinceprevious', data.value );
        setModify(true);
    }

    const handleChangeDaysPatient = (event, data) => {
        formikTitration.setFieldValue('dayspatienttreated', data.value );
        setModify(true);
    }

    

    //FORMULARIO DE ADD TITRATION al clicar Save

    const formikAddTitration = useFormik({
        initialValues: initialValuesAddTitrations(newTitration),
        validationSchema: Yup.object(newSchemaAddTitrations()),
        validateOnChange: false,
        onSubmit: async (formValue) =>{
            try {
                var tritationsDef= helpers.calculateTitrations(
                    formValues, pump, patient.pumpcapacity,modifiedTitrations,[formValue]);
             
                
                setFormatPercentage(formatSelectDataPerc(percentages));
                var finalValues = formValues; //AÑADIDO POR CARLOS, supongo que es correcto
                if(!tritationsDef.passed){
                  
                    setShowModalError(true);
                    setShowModalErrorText('No volume for more titrations.')
                    setNewTitration(null);
                } else {
                    
                    setNewTitration(tritationsDef.passed);
                    setTitrations(tritationsDef.titrations);
                    finalValues['titrations'] = tritationsDef.titrations; 
                    finalValues['tritrations']["titrations_to_API"]=tritationsDef["titrations_to_API"];
                    setmodifiedTitrations(tritationsDef["titrations_to_API"]);
                    window.titrationsAPI=tritationsDef["titrations_to_API"];
                    setFormValues(finalValues);
                   
                }

                setNewTitration(null);
            } catch (error) {
                throw error;
            }
        }
    });

    // EJECUTA CALCULADORA AL CAMBIAR VALORES EN ADD TITRATION

    const changeAddTitration=function(formValue){
       
        try {
         
            var formDef = formValue;
            formDef['dayspatienttreated'] = parseInt(formValue['dayspatienttreated']);
            formDef['dayssinceprevious'] = parseInt(formValue['dayssinceprevious']);

            // DEFAULTS ADD TITRATION//
            var tritationsDef =helpers.calculateTitrations(
                formValues, pump, patient.pumpcapacity,modifiedTitrations,[formDef]);
        
            
            setFormatPercentage(formatSelectDataPerc(percentages));
            console.log(tritationsDef);
            if(!tritationsDef.passed){
              
                setShowModalError(true);
                setShowModalErrorText("Insufficient volume. Impossible to carry out the treatment with the indicated dose."); 
            } else {
                
                //setNewTitration(tritationsDef.passed);
                //window.titrationsAPI = tritationsDef['titrations_to_API'];
            }
            
        } catch (error) {
            alert(error.message)
            throw error;
        }
         
    }

    if(add){
        changeAddTitration(formikAddTitration.values);
        setAdd(false);
    } 

    // GESTION VALORES DE ADD TITRATION
    
    const handleChangePercentageAdd = (event, data) => { 
        formikAddTitration.setFieldValue('doseporcentagechange', data.value );  
        setAdd(true);
    }

    const handleChangeDaysAdd = (event, data) => {
        formikAddTitration.setFieldValue('dayssinceprevious', data.value ); 
        setAdd(true);
    }

    const handleChangeDaysPatientAdd = (event, data) => {
        formikAddTitration.setFieldValue('dayspatienttreated', data.value );
  
        setAdd(true);
    }

    // FUNCION AL CLICAR ADD TITRATION el boton de la lista de titrations

    const showTritationAdd = (event, data) => {
        assignStep(4);
        var finalValues = formValues;

        // DEFAULTS ADD TITRATION//
        if(modifiedTitrations.length > 0) {
            var tritationsDef= helpers.calculateTitrations(
                formValues, pump, patient.pumpcapacity,modifiedTitrations,[
                    helpers.defaultAddtitrationvalues(formValues, pump, patient.pumpcapacity)
                ]);
        } else {
            var tritationsDef= helpers.calculateTitrations(
                formValues, pump, patient.pumpcapacity,[null],[
                    helpers.defaultAddtitrationvalues(formValues, pump, patient.pumpcapacity)
                ]);
        }
        

      
        setFormatPercentage(formatSelectDataPerc(percentages));

        if(!tritationsDef.passed){
          
            setShowModalError(true);
            setShowModalErrorText(helpers.getDosePercentageChangeOptions(formValues, pump, patient.pumpcapacity));
        } else {
          
            setNewTitration(tritationsDef.passed);
            //setTitrations(tritationsDef.titrations);
            finalValues['titrations'] = tritationsDef.titrations;
           

            formikAddTitration.setValues(helpers.defaultAddtitrationvalues(formValues, pump, patient.pumpcapacity));
            helpers.waitForDomElement(".titrationnum").then((selector) => {
                document.getElementsByClassName("titrationnum")[0].innerHTML =(tritationsDef.passed.index+1);
                document.getElementsByClassName("titrationdate")[0].innerHTML=tritationsDef.passed.tritrationdate;
              });
        }
        
    }

    // PRIMER FORMULARIO 

    const formik = useFormik({
        //ESTE ES EL FORMULARIO PRIMERA PAGINA DE RECHARGE PASO 1
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) =>{
            window.formValue=formValue;
         
            if (props.past){
                submitFirstStepRecharge();
            } else {
                if (ismodified){
            
                setShowConfirmRecharge(true);
                } else {
                    submitFirstStepRecharge(); 
                }
            }
            
        }
    });

    const submitFirstStepRecharge = function(){
        var formValue=window.formValue;
        
        var tritations={};
        try {
            if (Array.isArray(formValue['primarydrug'])){
             formValue['primarydrug'] = formValue['primarydrug'];
            } else {
             formValue['primarydrug'] = [formValue['primarydrug']];
            }
           
            var calculo =helpers.calculateTitrations(
                formValue, pump, patient.pumpcapacity);

             var percentages = helpers.getDosePercentageChangeOptions(formValue, pump, patient.pumpcapacity);

             if (calculo.errors.length==0){

                if(modifiedTitrations.length > 0) {
                    var tritations =helpers.calculateTitrations(
                        formValue, pump, patient.pumpcapacity,modifiedTitrations);
                } else {
                    var tritations =helpers.calculateTitrations(
                        formValue, pump, patient.pumpcapacity,[null]);
                }
              
                 
             } 
         
             if(!calculo){
                setShowModalErrorText(calculo.errors)
                setShowModalError(true);
               
             } else {
                 if(calculo.errors.length > 0){
                     if (showConfirmRecharge){
                        setShowConfirmRecharge(false);
                       
                     }
                     setShowModalErrorText(calculo.errors)
                     setShowModalError(true);
                
                   
                 } else {
                     //controlNavigation
                     //setToNext(true); //AHORA CONTROLA CONTROLNAVIGATION
                     //setCalculo(calculadora);
                     setCalculoData(calculo);
                 
                     setTitrations(tritations.titrations);
             
                     formValue['tritrations'] = tritations;
                     setFormValues(formValue);
                     setPercentages(percentages);
                     
                 
                     assignStep(2);
      
                 }
             }

         } catch (error) {
            throw error;
        }
    };

    // AÑADIR DROGA PRINCIPAL

    const formikAdd = useFormik({
        //primary drug
        initialValues: initialValuesDrug(),
        validationSchema: Yup.object(newSchemaDrug()),
        validateOnChange: false,
        onSubmit: async (formValue) =>{
           try {
                setInputData(formValue.concentrations);
                let data = {
                    drug: {
                        id: formValue.drug,
                        name: formValue.name,
                        unit: formValue.unit,
                        concentrations: formValue.concentrations,
                        isprimary: formValue.isprimary,
                        ismaindrug: false,
                    },
                    concentration: 0,
                    dailydose: 0,
                }
                console.log(data);
              
                setInputDataForm([data])
                //getDrugsSecondary(formValue.drug);
                getDrugsSecondary();
                getDrugsPrimary();
                setInputPrim(formValue);
                setShowModalPrim(false);
                setShowModal(false);
                window.useddrugs.push(formValue.drug);

            } catch (error) {
                //alert(error);
                throw error;
           }
        }
    });

    // AÑADIR DROGA SECUNDARIA

    const formikAddDrug = useFormik({
        //other drugs
        initialValues: initialValuesDrug(),
        validationSchema: Yup.object(newSchemaDrug()),
        validateOnChange: false,
        onSubmit: async (formValue) =>{
          
           try {
                let data = {
                    drug: {
                        id: formValue.drug,
                        name: formValue.name,
                        unit: formValue.unit,
                        concentrations: formValue.concentrations,
                        isprimary: formValue.isprimary,
                        ismaindrug: formValue.ismaindrug,
                    },
                    concentration: 0,
                    dailydose: 0,
                }
              

                const newList = list.concat(data);
                setList(newList);
             

                if(listSecondary.length > 0){
                    setListSecondary(listSecondary.concat(data));
                } else {
                    setListSecondary([data]);
                }

                setShowModalPrim(false);
                setShowModal(false);
                getDrugsSecondary();
                getDrugsPrimary();
                window.useddrugs.push(formValue.drug);

            } catch (error) {
                throw error;
           }
        }
    });

    // OTRAS FUNCIONES QUE VAN CORRECTAMENTE DE AYUDA A GESTION DE DATOS

    const firstNext = event => {
       //controlNavigation
      //setToNextLast(true); //AHORA CONTROLA CONTROLNAVIGATION
      assignStep(3);
    }

    const onClick = event => {
        setInputPrim(null);
        setShowResultsDrugs(false);
       
        removeUsedDrug(event.target.getAttribute("data-drugid"));
        getDrugsSecondary();
        getDrugsPrimary();
        openCloseButtonPrimary();
        formik.setFieldValue('primarydrug', [{
            drug: {
                id: '',
                name: '',
                unit: '',
                concentrations: [],
                isprimary: true,
                ismaindrug: true,
            },
            concentration: 0,
            dailydose: 0,
        }]);
    }

    const onClickSecondary = event => {
        const newList = list.filter(function(item) {
          
            return item.drug.name !== event.target.getAttribute("data-name");
        });
      
        setList(newList);

        const newDrug = listSecondary.filter(function(item) {
            return item.drug.name !== event.target.getAttribute("data-name");
        });
        setListSecondary(newDrug);
        removeUsedDrug(event.target.getAttribute("data-drugid"));
        
        getDrugsSecondary();
    }

    const handleChange = (event, data) => {
        console.log("handle change primary",data);
        const { value } = data;
        const { text, unit, concentrations, isprimary, ismaindrug } = data.options.find(o => o.value === value);
        formikAdd.setValues({drug: data.value, name: text, unit: unit, concentrations: concentrations, isprimary: isprimary, ismaindrug: ismaindrug });
    }

    const handleChangeSecondary = (event, data) => {

        const { value } = data;
        const { text, unit, concentrations, isprimary, ismaindrug } = data.options.find(o => o.value === value);
        formikAddDrug.setValues({drug: data.value, name: text, unit: unit, concentrations: concentrations, isprimary: isprimary, ismaindrug: ismaindrug });
    }

    const handleCheckChange = (event, data) => {
        const { checked } = data;
        handleChangeRecharge();
        if(checked){
            formik.setFieldValue('totalcapacity', true);
            formik.setFieldValue('refillamount', patient.pumpcapacity);
            //formik.setFieldValue('refillamount', pump.capacidadtotal[pump.capacidadtotal.length - 1]);
            setShowResults(false)
        } else {
            formik.setFieldValue('totalcapacity', false);
            //formik.setFieldValue('refillamount', '');
            setShowResults(true);
        }
    }

    const handleChangeRefillamount = (event, data) => {
        var value=data.value;
        if (value>patient.pumpcapacity){
            value=patient.pumpcapacity;
        }
        formik.setFieldValue('refillamount', value);
        
        handleChangeRecharge();
    }

    const handleConcentrationPrimaryChange = (event, data) => {
        formik.setFieldValue('primarydrug[0].concentration', data.value);
    }

    const handleDosePrimaryChange = (event, data) => {
        
        formik.setFieldValue('primarydrug[0].dailydose', data.value);
    }

    const handleConcentrationChange = (event, data) => {
        let arrayC = [];
        let dict = {};
        handleChangeRecharge();

        listSecondary.map((item) =>{
            if(item.drug.name == data.props){
                dict = {
                    drug: {
                        id: item.drug.id,
                        name: item.drug.name,
                        unit: item.drug.unit,
                        concentrations: item.drug.concentrations,
                        isprimary: item.drug.isprimary,
                        ismaindrug:item.drug.ismaindrug
                    },
                    concentration: data.value,
                    dailydose: item.dailydose,
                }

            } else {
                dict = {
                    drug: {
                        id: item.drug.id,
                        name: item.drug.name,
                        unit: item.drug.unit,
                        concentrations: item.drug.concentrations,
                        isprimary: item.drug.isprimary,
                        ismaindrug:item.drug.ismaindrug
                    },
                    concentration: item.concentration,
                    dailydose: item.dailydose,
                }

            }


            arrayC.push(dict);
        });

        setListSecondary(arrayC);

    }

    const handleDoseChange = (event, data) => {
        handleChangeRecharge();
        let array = [];
        let dict = {};
        listSecondary.map((item) =>{
            if(item.drug.name == data.props){
                dict = {
                    drug: {
                        id: item.drug.id,
                        name: item.drug.name,
                        unit: item.drug.unit,
                        concentrations: item.drug.concentrations,
                        isprimary: item.drug.isprimary,
                        ismaindrug: item.drug.ismaindrug
                    },
                    concentration: item.concentration,
                    dailydose: data.value,
                }
            } else {
                dict = {
                    drug: {
                        id: item.drug.id,
                        name: item.drug.name,
                        unit: item.drug.unit,
                        concentrations: item.drug.concentrations,
                        isprimary: item.drug.isprimary,
                        ismaindrug:item.drug.ismaindrug
                    },
                    concentration: item.concentration,
                    dailydose: item.dailydose,
                }
            }


            array.push(dict);
        });

        setListSecondary(array);

    }
    
    const handleCallback = (childData, index) =>{
        
      assignStep(4);
        
      setSingleTitration(childData);
      setShowTitration(true);
      setFormatPercentage(formatSelectDataPerc(percentages));
      setIndexTitration(index);
      setDefaultPerc(childData.doseporcentagechange);
      formikTitration.setValues({index: index, dayssinceprevious: childData.dayssinceprevious, doseporcentagechange: childData.doseporcentagechange, dayspatienttreated: childData.dayspatienttreated });
      
      helpers.waitForDomElement(".titrationnum").then((selector) => {
        document.getElementsByClassName("titrationnum")[0].innerHTML =(index+1);
        document.getElementsByClassName("titrationdate")[0].innerHTML=childData.tritrationdate;
      });
    }
    
    const currentSituation = () => {
       
        alert(props.props.past);
        if(props.props.past == '0'){
            return true;
        } else {
            return false;
        }
    }

    
    var clase="editrechargepresent";
    var saveword="SAVE";
    if (props.past){
        clase="editrechargepast";
        saveword="NEXT";
    }

    // FIN OTRAS FUNCIONES QUE VAN CORRECTAMENTE DE AYUDA A GESTION DE DATOS
    
    // HTML
    return (

        <div className={clase}>
         <ModalErrors title="Error"  children={errormessage} show={showModalError}  setShowModalError={ setShowModalError}  />
            { toList ? (
                <>
                    <Navigate to={`/patient/${props.props.state.patient}`} />
                </>
                ):(
                <>
                {toNext ? (
                <>
                { toNextLast ?
                    (
                    <main>
                        { titrations ? (

                            <>
                            {singleTitration ? (
                                <>
                                {/*
                                    //TODO: add to title
                                    <p>Increase the flow and daily dose</p>
                                    <p>{singleTitration.tritrationdate}</p>*/}

                                    <Form className='add-titration-form' onSubmit={formikTitration.handleSubmit}>

                                        <div className="card ui">
                                            <div className="content">
                                                <h4 className="nomarginbottom"> Days since original recharge and remaining volume in pump</h4>
                                                <div className="fieldpair">
                                                <div className="field ui input labelinput fd">
                                                
                                                    <label>
                                                    Days since original recharge
                                                    </label>
                                                    <Form.Input type="number" value={formikTitration.values.dayssinceprevious} onChange={handleChangeDays}  />
                                                    {/* <Form.Input type="number" value={formikTitration.values.dayssinceprevious} onChange={handleChangeDaysPatient} disabled={currentSituation}/> */}  
                                                </div>

                                                <div className="field fd">
                                                    <div className=" flex-space">
                                                        <div className="label small">
                                                            Remaining volume in the pump
                                                        </div>
                                                        <div className="value text-right">
                                                            {singleTitration.remainingvolumeinthepump.toFixed(3)}<span className="unit">ml</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>


                                        <h4>Previous parameters since <span className="label">{singleTitration.tritrationdate}</span></h4>
                                        <div className="list">
                                        <div className="card ui">
                                                    <div className="content">

                                                
                                            {singleTitration.previousparameters_output.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}> 
                                            
                                                        <h6 className="">{item.name} {item.unit}</h6>
                                                        <div className="fieldpair">
                                                        <div className="field fd">
                                                            <div className=" flex-space">
                                                                <div className="label small">
                                                                    Daily dose administered 
                                                                </div>
                                                                <div className="value text-right">
                                                                    {item.dailydoseadministered}<span className="unit">{item.unit}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="field fd">
                                                        <div className="flex-space ">
                                                            <div className="label small">
                                                                Pump concentration remaining
                                                            </div>
                                                            <div className="value text-right">
                                                                {item.pumpconcentrationremaining.toFixed(3)}<span className="unit">{item.unit}</span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                
                                                </React.Fragment>
                                                )
                                            })}
                                                </div>
                                                </div>
                                                </div>

                                        <div className="card ui minipadded">
                                            <div className="content">
                                            <h4 className="nomarginbottom">Dose porcentage change </h4>

                                            <div className="field ui input labelinput ">
                                                
                                                <label>
                                                Increase or decrease)
                                                </label>
                                                <Select
                                                    name="drug"
                                                    placeholder="Drugs"
                                                    className="big"
                                                    id="titrabigpercent"
                                                    options={formatPercentage}
                                                    value={formikTitration.values.dosepercentagechange}
                                                    onChange={handleChangePercentage}
                                                    defaultValue={defaultPerc}
                                                /> 
                                                        
                                            </div>
                                            </div>
                                        </div>


                                        <h4>New parameters until next tiritation</h4>
                                        <div className="card ui">
                                                    <div className="content">
                                        {singleTitration.newparametersuntilnexttitration_output.map((item, index) => {
                                                return (
                                                    
                                            
                                                        <div className="field " key={index}>
                                                            <div className=" flex-space">
                                                                <div className="label small">
                                                                    {item.name}
                                                                </div>
                                                                <div className="value text-right">
                                                                    {item.units.toFixed(3)}<span className="unit">{item.unit}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                )
                                            })}
                                        </div>
                                                </div>


                                        <div className="card ui importantcard">
                                            <div className="content">
                                            <h4>Remember</h4>
                                                <div className="field ">
                                                    <div className=" flex-space">
                                                        <div className="label small">
                                                        New continuous daily flow
                                                        </div>
                                                        <div className="value text-right">
                                                        {singleTitration.newcontinuousdailyflow.toFixed(3)} <span className="unit">ml</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="card ui ">
                                            <div className="content">
                                            <h4>How many days will the patient be treated with this new flow <small>(and daily dose)</small>?</h4>
                                                <div className="field ">
                                                <div className="field ui input labelinput ">
                                                        <label>
                                                        Days treated with the new flow
                                                        </label>
                                                    
                                                        <Form.Input placeholder="0" type="number" step="1" onChange={handleChangeDaysPatient} value={formikTitration.values.dayspatienttreated}/>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card ui ">
                                            <div className="content">
                                            <h4>End of the dialed period</h4>
                                            <div className="field ">
                                            <div className=" flex-space">
                                                <div className="label small">
                                                    Total volume remaining in the pump at the end of the dialed period 
                                                </div>
                                                <div className="value text-right">
                                                    {singleTitration.remainingvolumeatend.toFixed(3)} <span className="unit">ml</span>
                                                </div>
                                            </div>
                                            
                                            </div>
                                            </div>
                                            </div>

                                        
                                            <div className="text-center">
                                            {/*<Button type="submit" content="SAVE" primary fluid onClick={() => setSingleTitration(null)} />  */}
                                            <Button type="submit" content={saveword} primary fluid  onSubmit={formikTitration.handleSubmit} />                   
                                            </div>
                                    </Form>
                                   
                                    <ConfirmEditRecharge show={showConfirmTitration} children="Are you sure you want to modify the titration? If you need you can create a new titration" setShowConfirmRecharge={setShowConfirmTitration} onCancelConfirfRecharge={cancelConfirmTitration} onConfirmRecharge={confirmConfirmTitration} />     
                                </>
                            ) : (
                                <>
                                { newTitration ? (
                                    <>
                                    {/*
                                        //TODO: add to title
                                        <p>Increase the flow and daily dose</p>
                                        <p>{singleTitration.tritrationdate}</p>*/}
    
                                        <Form className='add-titration-form' onSubmit={formikAddTitration.handleSubmit}>
    
    
                                            <div className="card ui">
                                                <div className="content">
                                                    <h4 className="nomarginbottom"> Days since original recharge and remaining volume in pump</h4>
                                                    <div className="fieldpair">
                                                    <div className="field ui input labelinput fd">
                                                    
                                                        <label>
                                                        Days since original recharge
                                                        </label>
                                                        <Form.Input type="number" value={formikAddTitration.values.dayssinceprevious} onChange={handleChangeDaysAdd}  />
                                                        {/* <Form.Input type="number" value={formikTitration.values.dayssinceprevious} onChange={handleChangeDaysPatient} disabled={currentSituation}/> */}  
                                                    </div>
    
                                                    <div className="field fd">
                                                        <div className=" flex-space">
                                                            <div className="label small">
                                                                Remaining volume in the pump
                                                            </div>
                                                            <div className="value text-right">
                                                                {newTitration.remainingvolumeatend.toFixed(3)}<span className="unit">ml</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
    
    
                                            <h4>Previous parameters since <span className="label">{newTitration.tritrationdate}</span></h4>
                                            <div className="list">
                                            <div className="card ui">
                                                        <div className="content">
    
                                                    
                                                {newTitration.previousparameters_output.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}> 
                                                
                                                            <h6 className="">{item.name} {item.unit}</h6>
                                                            <div className="fieldpair">
                                                            <div className="field fd">
                                                                <div className=" flex-space">
                                                                    <div className="label small">
                                                                        Daily dose administered 
                                                                    </div>
                                                                    <div className="value text-right">
                                                                        {item.dailydoseadministered}<span className="unit">{item.unit}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div className="field fd">
                                                            <div className="flex-space ">
                                                                <div className="label small">
                                                                    Pump concentration remaining
                                                                </div>
                                                                <div className="value text-right">
                                                                    {item.pumpconcentrationremaining.toFixed(3)}<span className="unit">{item.unit}</span>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            </div>
                                                    
                                                    </React.Fragment>
                                                    )
                                                })}
                                                    </div>
                                                    </div>
                                                    </div>
    
                                            <div className="card ui minipadded">
                                                <div className="content">
                                                <h4 className="nomarginbottom">Dose porcentage change </h4>
    
                                                <div className="field ui input labelinput ">
                                                    
                                                    <label>
                                                    Increase or decrease)
                                                    </label>
                                                    <Select
                                                        name="drug"
                                                        placeholder="Drugs"
                                                        className="big"
                                                        id="titrabigpercent"
                                                        options={formatPercentage}
                                                        value={formikAddTitration.values.dosepercentagechange}
                                                        onChange={handleChangePercentageAdd}
                                                        defaultValue={defaultPerc}
                                                    /> 
                                                            
                                                </div>
                                                </div>
                                            </div>
    
    
                                            <h4>New parameters until next tiritation</h4>
                                            <div className="card ui">
                                                        <div className="content">
                                            {newTitration.newparametersuntilnexttitration_output.map((item, index) => {
                                                    return (
                                                        
                                                
                                                            <div className="field " key={index}>
                                                                <div className=" flex-space">
                                                                    <div className="label small">
                                                                        {item.name}
                                                                    </div>
                                                                    <div className="value text-right">
                                                                        {item.units.toFixed(3)}<span className="unit">{item.unit}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    
                                                    )
                                                })}
                                            </div>
                                                    </div>
    
    
                                            <div className="card ui importantcard">
                                                <div className="content">
                                                <h4>Remember</h4>
                                                    <div className="field ">
                                                        <div className=" flex-space">
                                                            <div className="label small">
                                                            New continuous daily flow
                                                            </div>
                                                            <div className="value text-right">
                                                            {newTitration.newcontinuousdailyflow.toFixed(3)} <span className="unit">ml</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
    
                                            <div className="card ui ">
                                                <div className="content">
                                                <h4>How many days will the patient be treated with this new flow <small>(and daily dose)</small>?</h4>
                                                    <div className="field ">
                                                    <div className="field ui input labelinput ">
                                                            <label>
                                                            Days treated with the new flow
                                                            </label>
                                                        
                                                            <Form.Input placeholder="0" type="number" step="1" onChange={handleChangeDaysPatientAdd} value={formikAddTitration.values.dayspatienttreated}/>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div className="card ui ">
                                                <div className="content">
                                                <h4>End of the dialed period</h4>
                                                <div className="field ">
                                                <div className=" flex-space">
                                                    <div className="label small">
                                                        Total volume remaining in the pump at the end of the dialed period 
                                                    </div>
                                                    <div className="value text-right">
                                                        {newTitration.remainingvolumeatend.toFixed(3)} <span className="unit">ml</span>
                                                    </div>
                                                </div>
                                                
                                                </div>
                                                </div>
                                                </div>
    
                                            
                                                <div className="text-center">
                                                {/*<Button type="submit" content="SAVE" primary fluid onClick={() => setSingleTitration(null)} />  */}
                                                <Button type="submit" content={saveword} primary fluid  onSubmit={formikAddTitration.handleSubmit} />                   
                                                </div>
                                        </Form>
                                       
                                        <ConfirmEditRecharge show={showConfirmTitration} children="Are you sure you want to modify the titration? If you need you can create a new titration" setShowConfirmRecharge={setShowConfirmTitration} onCancelConfirfRecharge={cancelConfirmTitration} onConfirmRecharge={confirmConfirmTitration} />     
                                    </>
                                    ) : (
                                    <>


                                
                                        <Form onSubmit={formikSave.handleSubmit}>

                                        <ThirdNext titrations={titrations} parentCallback={handleCallback}  />

                                        <p class="label text-center">From here, with the default values, it is impossible to continue doing titrations due to the low remaining volume.</p>
                                        <div className="padded addextrat">
                                            <div className="text-center"><Button className="small" type="button" content="Add extra Titration" secondary  onClick={showTritationAdd}/></div>
                                            <div className="padded text-center label">Make sure the dates are suitable for your calendar</div>
                                            
                                        </div>
                                        <div className=" text-center">
                                                <Button type="submit" content={saveword} primary fluid />
                                            </div>
                                        </Form>
                                        <ModalErrors title="Error"  children={errormessage} show={showModalError}  setShowModalError={ setShowModalError}  />
                                    </>
                                )}
                                </>
                            ) }

                            </>
                        ) : null }
                    </main>
                    ) :
                    (
                    <main>
                        <SecondNext formValues={formValues} calculoData={calculoData} />
                        <div className="text-center">
                        <Button type="button" content="NEXT" primary fluid onClick={firstNext}/>
                        </div>
                    </main>
                    )
                }
                </>
                ) : (
                
                <main>
                { actualRecharge ? (<Form className='add-recharge-form' onSubmit={formik.handleSubmit}>
                <div className="ui card formcard">
                <div className="content">
                    <div className='pump-refill'>

                        <h3>Pump Refill</h3>
                       
                        <div className="field ui input labelinput fd">
                        <label>Pump refill day</label>
                        <DatePicker placeholderText="MM/DD/YYYY" selected={formik.values.pumprefillday} onChange={(date) => {formik.setFieldValue('pumprefillday', date),handleChangeRecharge()}} />
                        </div>
                        <div className="fieldpair">
                        <div className="field ui input flex-space fd">
                            <label>
                                Refill pump completely
                            </label>
                            <Checkbox
                                toggle
                                name="pumprefillday"
                                checked={formik.values.totalcapacity}
                                onChange={handleCheckChange}
                            />
                        </div> 
                        { showResults ?
                         patient ?
                        <div id="refill" className='input refill field fd'>
                             <div className="field ui input labelinput">
                                <label>Refill amount</label>
                                <Form.Input placeholder="0"  type="number" step="1" name="refillamount" max={patient.pumpcapacity} onChange={handleChangeRefillamount} value={formik.values.refillamount} errors={formik.errors.refillamount}/>
                            </div>
                        </div>
                        : null
                        : null
                        }
                     </div>
                    </div>
                    </div>
                    </div>
                    <div className="ui card formcard">
                    <div className="content">
                    <div className="drugs-list">
                       
                        <h3>Drugs and presentations</h3>
                        <div className="fieldpair">
                        <div className="fd inline-block minipaddedbottom">
                        <h4>Primary drug</h4>
                        <div id="primary-drugs" className="">
                            { inputPrim ?
                                <div className='input-drug  card ui dark'>
                                <div className="content">
                                <div className="flex-space">
                                <h5>{inputPrim.name} <small>{inputPrim.unit}</small></h5>
                                <div className="closedrug clickable" onClick={onClick} data-name={inputPrim.name} data-drugid={inputPrim.id}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z" fill="#212D68"/></svg>

                                </div>

                                </div>
                                    <div id={inputPrim.name} className="drug-input">
                                    <div className="field ui input labelinput">
                                        <label>Concentration</label>
                                        <Select
                                            name={`concentration-${inputPrim.name}`}
                                            placeholder="Concentration"
                                            options={concentrationPrimFormat}
                                            value={formik.values.primarydrug.concentration}
                                            onChange={handleConcentrationPrimaryChange}
                                            props={inputPrim.name}
                                        />
                                        </div>
                                        <div className="field ui input labelinput">
                                        <label>Daily dose</label>
                                        <Form.Input placeholder="0.0"  type="number" step="0.1" onChange={handleDosePrimaryChange} value={formik.values.primarydrug.dailydose}/>
                                        </div>
                                    </div>
                                </div>
                                </div>

                            : null }
                            </div>
                            



                        <div className="text-left">
                        { showPrimButton ? <Button type="button" className="add-drug fluid" secondary onClick={openCloseModalPrimary}>Add primary drug</Button> : null }
                        </div>

                        </div>
                        

                        <div className="fd inline-block">
                        <h4>Other drugs</h4>
                        <div id="secondary-drugs" >
                        {list ? (
                        <>
                        {list.map((item, index) => {
                                return (
                                <div className="input-drug card ui dark" key={index}>
                                <div className="content">
                                <div className="flex-space">
                                    <h5>{item.drug.name} <small>{item.drug.unit}</small></h5>
                                    <div className="closedrug clickable" onClick={onClickSecondary} data-name={item.drug.name} data-drugid={item.drug.id}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z" fill="#212D68"/></svg>
                                    </div>
                                </div>
                                    <div id={item.drug.name} className="drug-input">
                                    <div className="field ui input labelinput">
                                        <label>Concentration</label>
                                        <Select
                                            name={`concentration-${item.drug.name}`}
                                            placeholder="Concentration"
                                            options={concentrationFormat[index]}
                                            value={formik.values.concentration}
                                            onChange={handleConcentrationChange}
                                            props={item.drug.name}
                                            defaultValue={listSecondary[index] ? (listSecondary[index].concentration) : (null)}
                                        />
                                        </div>
                                        <div className="field ui input labelinput">
                                        <label>Daily dose</label>
                                        <Form.Input placeholder="0.0" type="number" step="0.1" onChange={handleDoseChange} props={item.drug.name} value={formik.values.dailydose} defaultValue={listSecondary[index] ? (listSecondary[index].dailydose) : (null)}/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                )
                        })}
                        </>
                        ): (
                            <></>
                        )}
                            
                            <div className="text-left">
                            <Button type="button" className="add-drug fluid" secondary onClick={openCloseModal}>Add secondary drug</Button>
                            </div>
                       
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>


                    <div className="ui card formcard">
                    <div className="content">
                    <div className="refill-day">

                        <h3>Days until next refill</h3>
                        <div className="field ui input labelinput">
                        <label>Days until next refill</label>
                        <Form.Input
                            type="Number"
                            
                            name="daysuntilnextrefill"
                            placeholder="0"
                            value={formik.values.daysuntilnextrefill}
                            onChange={e =>{formik.handleChange(e);handleChangeRecharge()}}
                            error={formik.errors.daysuntilnextrefill}
                        />
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="text-center">
                    <Button type="submit" content="NEXT" primary fluid />
                    </div>

                </Form>) : (<></>) }
                

                <ModalBasic title="Add primary drug" show={showModalPrim} onClose={openCloseModalPrimary}>
                    <Form className='add-edit-patient-form' onSubmit={formikAdd.handleSubmit}>
                        <Select
                            id="addPrimaryDrug"
                            name="drugPrim"
                            placeholder="Drugs"
                            options={drugsPrimaryFormat}
                            value={formikAdd.values.drug}
                            error={formikAdd.errors.drug}
                            onChange={handleChange}
                        />
                        <Button type="submit" content="Add" onClick={openCloseButtonPrimary} primary fluid />
                    </Form>
                </ModalBasic>

                <ModalBasic title="Add secondary drug" show={showModal} onClose={openCloseModal}>
                    <Form
                        className='add-edit-patient-form'
                        onSubmit={formikAddDrug.handleSubmit}
                    >
                        <Select
                            name="drug"
                            placeholder="Drugs"
                            options={drugsFormat}
                            value={formikAddDrug.values.drug}
                            error={formikAddDrug.errors.drug}
                            onChange={handleChangeSecondary}
                        />
                        <Button type="submit" content="Add" primary fluid />
                    </Form>
                </ModalBasic>
                <ConfirmEditRecharge show={showConfirmRecharge} children="Are you sure you want to modify the recharge? All data will be afected" setShowConfirmRecharge={setShowConfirmRecharge} onCancelConfirfRecharge={cancelConfirmRecharge} onConfirmRecharge={confirmConfirmRecharge} />
                
                </main>
                )}
                </>
            )}
        </div>
        )
    // FIN HTML
}


// FUNCIONES SECUNDARIAS PARA SETEAR FORMIK

function formatSelectDataPerc(data){
    return map(data, (item) => 
    ({
        key: item,
        text: item,
        value: item
    })); 

}

function formatSelectDataConcPrim(data){
        return map(data, (item) =>
        ({
            key: item,
            text: item.toString(),
            value: item
        }));

}

function formatSelectDataConc(data){
    let array = [];
    for (var i = 0; i < data.length; i++) {
       
        const concentrations = map(data[i].drug.concentrations, (item) =>
        ({
            key: item,
            text: item.toString(),
            value: item
        }));

        array.push(concentrations);
    }

    return array;
}

function formatSelectDataPump(data){
    if(data){
        return map(data.capacidadtotal, (item) =>({
            key: item,
            text: item.toString(),
            value: item
        }));
    } else {
        return [];
    }

}

function formatSelectData(data){
   
    return map(data, (item, index) => ({
        key: index,
        text: item.name,
        value: item.id,
        unit: item.unit,
        concentrations: item.concentrations,
        isprimary: item.isprimary,
        ismaindrug: 'false'
    }))
}

function formatSelectDataPrim(data){
   
    return map(data, (item, index) => ({
        key: index,
        text: item.name,
        value: item.id,
        unit: item.unit,
        concentrations: item.concentrations,
        isprimary: item.isprimary,
        ismaindrug: 'true'
    }))
}

function initialValues(){
    
    return {
    patient: '',
    totalcapacity: false,
    pumprefillday: '',
    refillamount: '',
    daysuntilnextrefill: '5',
    primarydrug: [{
        drug: {
            id: '',
            name: '',
            unit: '',
            concentrations: [],
            isprimary: 'true',
            ismaindrug: 'true',
        },
        concentration: '',
        dailydose: '',
    }],
    otherdrugs: [
        {
            drug: {
                id: '',
                name: '',
                unit: '',
                concentrations: [],
                isprimary: 'false',
                ismaindrug: 'false',
            },
            concentration: '',
            dailydose: '',
        },
    ],
    tritrations: [],
  };
}

function newSchema() {
    return{
        
    }
}

function initForms(){
    return 1;
}

function initialValuesDrug(){
    return {
        drug: "",
        name: "",
        unit: "",
        concentrations: [],
        isprimary: "",
        ismaindrug: ""
    }
}

function newSchemaDrug() {
    return{
        drug: Yup.number().required(true),
        name: Yup.string().required(true),
        unit: Yup.string().required(true),
    }
}

function initialValuesTitrations(){
  return {
    index: "",
    dayssinceprevious:"",
    doseporcentagechange:"",
    dayspatienttreated:"",
  }
}

function newSchemaTitrations() {
  return{
    index: Yup.number().required(true),
    dayssinceprevious:Yup.number().required(true),
    doseporcentagechange:Yup.number().required(true),
    dayspatienttreated:Yup.number().required(true),
  }
}

function initialValuesAddTitrations(titration){
    return {
      index: "",
      dayssinceprevious:"",
      doseporcentagechange:"",
      dayspatienttreated:"",
    }
  }

function newSchemaAddTitrations() {
    return{ 
      dayssinceprevious:Yup.number().required(true),
      dayspatienttreated:Yup.number().required(true),
    }
  }

// Convert Date.now() to Formatted Date in "dd-MM-YYYY".
function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('-')
}

// FIN FUNCIONES SECUNDARIAS PARA SETEAR FORMIK