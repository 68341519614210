import { useState } from "react";
import { getPumpsAPI } from "../api/pump";
import { useAuth } from ".";

export function usePump() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    const [pumps, setPumps] = useState(null);
    const [pump, setPump] = useState(null);
    const {auth} = useAuth();

    const getPumps = async() => {
        try {
            setLoading(true);
            const response = await getPumpsAPI(auth.token);
            setLoading(false);
            setPumps(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const getSinglePump = async(pump) => {
        try {
            setLoading(true);
            const response = await getPumpsAPI(auth.token);
            var dict = []
            response.map((i) => {
                if(i.id == pump){
                    dict = i
                }
            });
            setLoading(false);
            setPump(dict);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    return {
        loading,
        error,
        pumps,
        pump,
        getPumps,
        getSinglePump
    };
}