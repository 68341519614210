import React from 'react';
import { Modal,Button } from "semantic-ui-react";


export function ModalErrors(props) {
    const { show,size, title, children, onClose , setShowModalError} = props;

    return (
        <>
        <Modal className="modal-basic" open={show} onClose={onClose} size={size} closeOnDocumentClick={true} closeOnDimmerClick={true} >
       
            {title && <Modal.Header>
                {title}
            </Modal.Header>}

            <Modal.Content>
                {children}
            </Modal.Content>

            <Modal.Actions>
      
                <Button  primary onClick={() =>  setShowModalError(false)}>
               OK
                </Button>
            </Modal.Actions>
      
        </Modal>
        </>
    );
}

ModalErrors.defaultProps = {
    size: "small",
    show:false,
}
