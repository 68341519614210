import React, { useEffect } from "react";
import {Loader, Button} from "semantic-ui-react";
import { useAuth, usePump } from "../../hooks";
import { AddEditPatientForm } from "../../components/Client";

export function AddPatient(props) {
    const { logout, auth } = useAuth();
    const { loading, pumps, getPumps } = usePump();
   

    useEffect(() => getPumps(), []);

    return (
        <>
            {loading ? (
                <Loader active inline="centered">
                    Loading...
                </Loader>
            ) : (

                <AddEditPatientForm pumps={pumps} />
                
            )}

        </>
    )
}
