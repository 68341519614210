import React,  {  useEffect }from 'react';
import {Table, Image, Button, Icon } from "semantic-ui-react";
import { map } from "lodash";
import "./patientClient.scss";
import { useRecharge,useAuth } from "../../../../hooks";
import { Calculator } from "../../../../layouts/CalculatorLayout/Calculator";
import { Link } from 'react-router-dom';
import * as helpers from '../../../../helpers.js';

window.runs=0;
export function ListPatients(props) {
    const { patients } = props;
   
    const {
      auth
    } = useAuth();

   

    const patientsXLSURL=global.config.domain+"download/patients/?token="+auth.token
  
    if (patients){

    
    if(patients.length>0){
     
      var maxruns=5;
        return (
            <div className="list-pacients list " key="patientlist">

                <div className="text-center">
                  <a className="link ternary" target="_blank" href={patientsXLSURL}>DOWNLOAD PATIENTS XLS</a>
                </div>

                    {map(patients, (patient, index) => {
                      console.log(window.runs);
                      var clase="ui card";
                      if (!patient.patientactive){
                        clase="ui card notactive";
                      }

                        if(patient.recharges){
                            if(Object.keys(patient.recharges).length > 0){
                              
                              var output="EXPIRED"
                              
                              if (patient.pump){
                                  if (patient.recharges.length){
                                    if(runs<maxruns){
                                      window.runs++;
                                    setTimeout(function(){
                                      
                                      var  titrations=helpers.calculateTitrations(patient.recharges[0], patient.pumpobject,patient.pumpcapacity,[null]);
                                    
                                     
                                      output=titrations["nextfuturerefilltitration"];
                                      if (!output){
                                          output="EXPIRED"
                                      }
                                    }, 10000*window.runs); 
                                  }
                                  }
                              }
                              
                        
                                return (

                                  <Link className={clase} key={index} to={`/patient/${patient.id}`}>
                                  <div className="content">
                                    <h3 className="patientname">{patient.patientid}</h3>
                                    <div className="flex-space">
                                    <div>
                                     <div className="label">Next refill </div> 
                                    <div className="value">{output}</div>


                                    </div>
                                    <div>
                                          <div className="label">#Recharges</div>
                                          <div className="value strong">{Object.keys(patient.recharges).length}</div>

                                    </div>
                                    </div>
                                    </div>
                                  </Link>
                                )

                            } else {
                                return (
                                    <Link className={clase} key={index} to={`/patient/${patient.id}`}>
                                      <div className="content">
                                    <h3>{patient.patientid}</h3>
                                    <div className="flex-space">
                                    <div>
                                      <div className="label">No Refill</div>

                                    </div>
                                    <div>
                                        <div className="label">#Recharges</div>
                                        <div className="value strong">0</div>

                                    </div>
                                    </div>
                                      </div>
                                    </Link>
                                  )

                            }
                        }


                    })}

            </div>
        )
    } else {
        return (
            <div className="card ui  fullcard2 fullcard" >
              <div className="content text-center padded  ">
               
                  <p class="fullcardinfo padded">You haven't added any patient yet</p>
                  
                  <Button href="/add-patient" className="ui fluid primary button"  content="ADD FIRST PATIENT" primary fluid />
             
              </div>
            </div>
        )
    }
  } else {
    return (
      <div>There was an error</div>
    )
  }
}
