import React from 'react';
import { Navigation } from "./routes";
import { ToastContainer } from "react-toastify";
import { AuthProvider,FormStepProvider } from './context'

export default function App() {
  return (
    <AuthProvider>
        <Navigation />
        <ToastContainer 
          position="bottom-center"
          autoClose="{5000}"
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
    </AuthProvider>
  )
}

