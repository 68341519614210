import { useState } from "react";
import { getPatientsAPI, addPatientAPI, getPatientAPI ,editPatientAPI} from "../api/patient";
import { useAuth } from ".";

export function usePatient() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    const [patients, setPatients] = useState(null);
    const [patient, setPatient] = useState(null);
    const {auth} = useAuth();

    const getPatients = async() => {
        try {
            setLoading(true);
            const response = await getPatientsAPI(auth.token);
            setLoading(false);
            setPatients(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const addPatient = async (data) => {
        try {
            setLoading(true);
            const response = await addPatientAPI(data, auth.token);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    }

    const getPatient = async (data) => {
        try {
            setLoading(true);
            const response = await getPatientAPI(data, auth.token);
            setLoading(false);
            setPatient(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    }

    const editPatient = async (id,data) => {
    
        try {
            setLoading(true);
            
            const response = await editPatientAPI(id,data, auth.token);
            setLoading(false);
            setPatient(response);
        } catch (error) {
            setLoading(false);
         
            setError(error);
        }
    }

    return {
        loading,
        error,
        patients,
        patient,
        getPatients,
        addPatient,
        getPatient,
        editPatient
    };
}