import routesClient from "./routes.client";
import routesCalculator from "./routes.calculator";
import { Error404 } from "../pages";
import { BasicLayout } from "../layouts";

const routes = [
    ...routesClient,
    ...routesCalculator,
    {
        layout: BasicLayout,
        component: Error404
    }
]

export default routes;