import { BASE_API } from "../utils/constants";

export async function getRechargesAPI(token, patient){
    try {
        const url = `${BASE_API}api/recharges/${patient}/`;
        const params = {
            headers:{
                Authorization: `Token ${token}`,
                
            }
        }

        const response = await fetch(url, params);
        const result = await response.json();
        localStorage.setItem('recharges', JSON.stringify(result));
        return result;

    } catch(error) {
        if(localStorage.getItem('recharges')){
            const result = localStorage.getItem('recharges');
            return result;
        } else {
            throw error;
        }  
    }
}

export async function getRechargeAPI(token, recharge){
    try {
        const url = `${BASE_API}api/recharges/${recharge}`;
        const params = {
            headers:{
                Authorization: `Token ${token}`,
                
            }
        }

        const response = await fetch(url, params);
        const result = await response.json();
        return result;

    } catch(error) {
        if(localStorage.getItem('recharges')){
            const result = localStorage.getItem('recharges').find(item => item.id === recharge);
            return result;
        } else {
            throw error;
        }
        
        
    }
}

export async function addRechargeAPI(data, token){
    try {
        const url = `${BASE_API}api/recharges/`;
        const params = {
            method: "POST",
            headers:{
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        } 
        const response = await fetch(url, params);
        const result = await response.json();
        return result;

    } catch(error) {
        var existingEntries = JSON.parse(localStorage.getItem("allAddRecharge"));
        localStorage.setItem("addedRecharge", JSON.stringify(data));
        existingEntries.push(data);
        localStorage.setItem("allAddRecharge", JSON.stringify(existingEntries));
        var localSave = true;
        return localSave;
    }
}

export async function editRechargeAPI(data, token){
    try {
        const url = `${BASE_API}api/recharges/${data.id}/`;
        const params = {
            method: "PUT",
            headers:{
                Authorization: `Token ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        } 
        const response = await fetch(url, params);
        const result = await response.json();
        return result;

    } catch (error) {
        var existingEntries = localStorage.getItem('recharges').find(item => item.id === recharge);

        if(existingEntries){
            localStorage.setItem("recharge", JSON.stringify(data)).find(item => item.id === recharge);
            var localEditSave = true;
            return localEditSave;
        } else {
            throw error;
        }
        
    }
}