import React, {useState, useEffect} from 'react';
import { Login } from "../../pages/Client"
import { useAuth } from "../../hooks";
import { BottomMenu, TopMenu,RechargeHeader,Iframe } from "../../components/Client"
import "../../styles/client/ClientLayout.scss";

export function AssignHeader(props){
  const step=1
  const stepstotal=3



  if (props.id=='addrecharge' || props.id=='editrecharge'){
    return (<RechargeHeader title={props.title} step={step} stepstotal={stepstotal} />)
  }

  return  (<TopMenu title={props.title} progress={23} />)
}



export function ClientLayout(props) {

    const { children } = props;
    const { auth } = useAuth();
  

    //const [title, setTitle] = useState(null);

    //useEffect(() => setTitle(urlName(children.type.name)));

    if(!auth) return <Login />;

    const layoutid="p_"+props.id;

    return (
      <div className="client-layout" id={layoutid}>
        <div className="client-layout__menu">
         <AssignHeader title={props.title} id={props.id} />
        </div>
          {(props.isiframe) ? 
              <Iframe path={props.path} />
              :            
            <div className="client-layout__main-content">
            <div className="wrapmaincontent">
              <div className="desktop">
                <div className="hchild text-center">{props.title}</div>
                  </div>
                  {children}
                  </div>
            </div>            
          }
        <div className="client-layout__menu sections">
          <BottomMenu title={props.title} />
        </div>
        <div id="legales" className="desktop">
        <a className="link ui tertiary" target="_parent" href="/legal/cookies">Cookies policy</a>
        <a className="link ui tertiary" target="_parent" href="/legal/notice">Legal notice</a>
        <a className="link ui tertiary" target="_parent" href="/legal/privacy">Privacy policy</a>
        </div>
      </div>
     
    );
}

