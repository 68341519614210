import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from "semantic-ui-react";
import { usePump, usePatient } from '../../hooks';
import { AddEditPatientForm } from '../../components/Client';

export function EditPatient(props) {
    const { loading, pumps, getPumps } = usePump();
    const { loadingpatient, patient, getPatient } = usePatient();
    const [isLoaded, setIsLoaded] = useState(false);
    const { id } = useParams();
    var idDef = parseInt(id);


    useEffect(() => getPumps(), []);
    useEffect(() => getPatient(idDef), []);

    useEffect(() => {
        if (patient) {
            setIsLoaded(true);
        }
    }, [patient])


    if (isLoaded) {
        return (
            <>
                {loadingpatient ? (<></>) : (<>
                    {loading ? (
                        <Loader active inline="centered">
                            Loading...
                        </Loader>
                    ) : (
                        <AddEditPatientForm pumps={pumps} patient={patient} />
                    )}
                </>)}
            </>
        )
    } else {
        return (<></>)
    }
}
