import React from 'react';
import { Modal,Button,Confirm  } from "semantic-ui-react";


export function ConfirmEditRecharge(props) {
    const { show, children, onCancelConfirfRecharge ,onConfirmRecharge, setShowConfirmRecharge} = props;

 
    return (
   
        <Modal open={show} >
            <Modal.Header>
                Confirm changes
            </Modal.Header>

            <Modal.Content>
                {children}
            </Modal.Content>

            <Modal.Actions>
                <Button  primary onClick={onConfirmRecharge}>
                OK
                </Button>
                <Button  secondary onClick={onCancelConfirfRecharge}>
                CANCEL
                    </Button>
            </Modal.Actions>
        </Modal>

    );
}

ConfirmEditRecharge.defaultProps = {
    size: "small",
    show:false,
}
