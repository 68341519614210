import { useState } from "react";
import { getRechargesAPI, getRechargeAPI, addRechargeAPI, editRechargeAPI } from "../api/recharge";
import { useAuth,usePump } from ".";
import { Calculator } from "../layouts/CalculatorLayout/Calculator";
import { useContext } from "react";



export function useRecharge() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    const [recharges, setRecharges] = useState(null);
    const [recharge, setRecharge] = useState(null);
    const [toList, setToList] = useState(false);
    const {auth} = useAuth();
    const { pump, getSinglePump } = usePump();

    const getRecharges = async(patientid) => {
        try {
            setLoading(true);
            const response = await getRechargesAPI(auth.token, patientid);
            setLoading(false);
            setRecharges(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const getRecharge = async(rechargeid) => {
        try {
            setLoading(true);
            const response = await getRechargeAPI(auth.token, rechargeid);
            setLoading(false);
            useEffect(() => getSinglePump(response.patient), []);
         
            setRecharge(response);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const editRecharge = async(data) => {
        try {
            setLoading(true);
            const response = await editRechargeAPI(data, auth.token);
            setLoading(false);
            setToList(true);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

   
    const addRecharge = async (data) => {
        try {
            setLoading(true);
            const response = await addRechargeAPI(data, auth.token);
            setToList(true); //HACE QUE SE VUELVA AL LISTADO DE PACIENTES AL COMPLETAR EL FORMULARIO DE SAVE RECHARGE
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    }

    return {
        loading,
        error,
        recharges,
        recharge,
        getRecharges,
        getRecharge,
        editRecharge,
        addRecharge,
        toList,
    };
}