import React, { useState, useEffect } from 'react';
import { Form, Button, Checkbox, Select, TextArea } from "semantic-ui-react";
import "./AddEditPatientForm";
import { map } from "lodash";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Navigate } from 'react-router-dom';
import { usePatient,useAuth } from '../../../../hooks';
import * as helpers from '../../../../helpers.js';

export function AddEditPatientForm(props) {
    var initialpumpcapacity={};
    if (props.patient){
        initialpumpcapacity=props.patient.pumpcapacity
    }

    const { addPatient,editPatient } = usePatient();
    const [pumpsFormat, setPumpsFormat] = useState([]);
    const [pumpFormat, setPumpFormat] = useState([]);
    //const [selectedpump,setSelectedpump]=useState(initialpumpcapacity);
   
    const [toNext, setToNext] = useState(false);
    const {
        auth
      } = useAuth();
    const { patient, pumps } = props;
      
    const knowmoreurl="support"
  


    useEffect(() => {
        setPumpsFormat(formatSelectData(pumps))
    }, [pumps]);

    if (props.patient){
        useEffect(() => {
            setPumpFormat(formatSelectDataPumpcapacity(patient.pumpobject));
        }, [patient.pumpobject]);
     
    }

    useEffect(() => {
        helpers.waitForDomElement("#pumpcapacity .item").then((selector) => {
           
            document.querySelector('#pumpcapacity .divider.text').innerHTML=props.patient.pumpcapacity;
         });
    },[])
    /*
    useEffect(()=>    
 
        setTimeout(function(){
          
            setPumpCapacityFormated(pumpFormat,pumps);
            //setPumpFormat(formatSelectDataPumpcapacity(initialpumpcapacity));
        }, 1000), [initialpumpcapacity]);
        */
    
    const setPumpCapacityFormated=function(pumpid,pumps){
        pumps.forEach((p)=>{
            if (pumpid==p.id){
               
                setPumpFormat(formatSelectDataPumpcapacity(p));
               // setSelectedpump(p);
            }
        })
    }
    
    var patienturl="/";
    if (patient){
        patienturl="/patient/"+patient.id;
    } 
    

    const formik = useFormik({
        initialValues: initialValues(patient),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
                if (patient) {
                    //edit
                    try {
                        await editPatient(patient.id,formValue);
                        setToNext(true);
                    } catch (error) {
                        throw error;
                    }
                } else {
                    //add new
                    try {
                        await addPatient(formValue);
                        setToNext(true);
                    } catch (error) {
                       
                        throw error;
                    }
                }
           
           
        }
    })

    return (
        <Form className='add-edit-patient-form' onSubmit={formik.handleSubmit}>
            <div className="ui card formcard">
                <div className="content">

                    {toNext ? <Navigate to={patienturl} /> : null}
                    <Form.Input
                        name="patientid"
                        placeholder="Patient ID"
                        value={formik.values.patientid}
                        onChange={formik.handleChange}
                        error={formik.errors.patientid}
                    />
                    <div className="fielddescription">We recommend for security reasons to not use any personal data such as names or surnames <a href={knowmoreurl}>know more</a></div>
                    <div className="field">
                        <Select
                            name="pump"
                            placeholder="Pump Model"
                            options={pumpsFormat}
                            value={formik.values.pump}
                            error={formik.errors.pump}
                            onChange={(_, data) => {
                                formik.setFieldValue('pump', data.value);
                                setPumpCapacityFormated(data.value,pumps);
                                
                        }}
                        />
                    </div>
                     
                    <div className="field">
                        <Select
                                id="pumpcapacity"
                                name="pumpcapacity"
                                placeholder="Pump capacity"
                                options={pumpFormat}
                                value={formik.values.pumpcapacity}
                                errors={formik.values.pumpcapacity.errors}
                                onChange={(_, data) => formik.setFieldValue('pumpcapacity', data.value)}
                            />

                    </div>
                    
                    <Form.Input
                        type="Number"
                        name="implantationyear"
                        placeholder="Year of pump implantation"
                        value={formik.values.implantationyear}
                        onChange={formik.handleChange}
                        error={formik.errors.implantationyear}
                    />
                    <div className="flex-space field checkfield">
                        <label>
                            Cancer Pain
                        </label>
                        <Checkbox
                            toggle
                            name="cancer_pain"
                            checked={formik.values.cancer_pain}
                            onChange={(_, data) => formik.setFieldValue('cancer_pain', data.checked)}
                        />
                    </div>
                    <div className="field">
                        <div className="ui input">
                            <TextArea
                                name="notes"
                                placeholder="Notes"
                                value={formik.values.notes}
                                onChange={formik.handleChange}
                                error={formik.errors.notes}
                            />
                        </div>
                    </div>
                    <div className="flex-space field checkfield">
                        <label>
                            Active
                        </label>
                        <Checkbox
                            toggle
                            name="patientactive"
                            checked={formik.values.patientactive}
                            onChange={(_, data) => formik.setFieldValue('patientactive', data.checked)}
                        />
                    </div>



                </div>
            </div>
            <div className="text-center">
                <Button type="submit" className=" stickybutton" content="SAVE" primary fluid />
            </div>
        </Form>
    )
}

function formatSelectData(data) {
    return map(data, (item) => ({
        key: item.id,
        text: item.name,
        value: item.id
    }))
}

function formatSelectDataPumpcapacity(data){
    if(data){
        return map(data.capacidadtotal, (item) =>({
            key: item,
            text: item.toString(),
            value: item
        }));
    } else {
        return [];
    }

}


function initialValues(patient) {
    if (patient) {
        let implantationyear="";
        if(patient.implantationyear){
            implantationyear=patient.implantationyear;
        }
        
        return {
            patientid: patient.patientid,
            pump: patient.pump,
            pumpcapacity:patient.pumpcapacity,
            implantationyear: implantationyear,
            notes: patient.notes,
            patientactive: patient.patientactive,
            cancer_pain: patient.cancer_pain,
        }
    } else {
        return {
            patientid: "",
            pump: "",
            pumpcapacity:10,
            implantationyear: "",
            notes: "",
            patientactive: true,
            cancer_pain: false,
        }
    }
}

function newSchema() {
    return {
        patientid: Yup.string().required(true),
        pump: Yup.number().required(true),
        pumpcapacity:Yup.number().required(true),
        implantationyear: Yup.number().notRequired(),
        notes: Yup.string().notRequired(),
        patientactive: Yup.boolean().required(false),
        cancer_pain: Yup.boolean().required(false)
    }
}
