import React from "react";
import { getMeAPI } from "../api/user";
import { useAuth } from ".";

export function useUser() {
    const {auth} = useAuth();

    const getMe = async(token) => {
        try {
            const response = await getMeAPI(token);
            return response;
        } catch (error) {
            throw error;
        }
    };

    return {
        getMe,
    };
}