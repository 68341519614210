import React, { useEffect } from "react";
import {Loader, Button} from "semantic-ui-react";
import { useAuth, useRecharge } from "../../hooks";
import {useLocation} from "react-router-dom";
import { ModalBasic } from "../../components/Client/Common"
import { AddRechargeForm } from "../../components/Client";

export function AddRecharge(props) {
    const { loading, logout, auth,assignStep } = useAuth();  
    let data = useLocation();

    return (
        <>
            {loading ? (
                <Loader active inline="centered">
                    Loading...
                </Loader>
            ) : (
                <div>
                    <AddRechargeForm props={data} patient={props}/>
                </div>
            )}
            
        </>
    )
}