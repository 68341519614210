import { useState } from "react";
import { getDrugsAPI } from "../api/drug";
import { useAuth } from ".";
import { map } from "lodash";

export function useDrug() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    const [drugs, setDrugs] = useState(null);
    const [drugsSecondary, setDrugsSecondary] = useState(null);
    const [drugsPrimary, setDrugsPrimary] = useState(null);
    const [drug, setDrug] = useState(null);
    const {auth} = useAuth();

    const getDrugs = async() => {
        try {
            setLoading(true);
            const response = await getDrugsAPI(auth.token);
            setLoading(false);
            setDrugs(response);
        } catch (error) {
            
            setLoading(false);
            setError(error);
        }
    };

    const getDrugsPrimary = async() => {
        try {
            setLoading(true);
            const response = await getDrugsAPI(auth.token);
            var dict = []
            response.map((item) => {
                if(item.isprimary){
                    if (!window.useddrugs.includes(item.id)){
                        dict.push(item);
                    }
                }
            })
            setLoading(false);
            setDrugsPrimary(dict);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    
    const getDrugsSecondary = async() => {
        //AQUI SE FILTRAN LAS SECONDARY
        try {
            setLoading(true);
            const response = await getDrugsAPI(auth.token);
            var dict = []
            response.map((item) => {
                //if(item.id != drug){
                if (!window.useddrugs.includes(item.id)){
                    dict.push(item);
                }
            });
            setLoading(false);
            setDrugsSecondary(dict);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    const getSingleDrug = async(drug) => {
        try {
            setLoading(true);
            const response = await getDrugsAPI(auth.token);
          
            var dict = []
            response.map((i) => {
                if (!window.useddrugs.includes(i.id)){
                //if(i.id == drug){
                    //dict = i
                    dict.push(i);
                }
            });
            setLoading(false);
            setDrug(dict);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    return {
        loading,
        error,
        drugs,
        drug,
        getDrugs,
        drugsPrimary,
        drugsSecondary,
        getDrugsPrimary,
        getDrugsSecondary,
        getSingleDrug
    };
}