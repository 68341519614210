import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {Loader, Button} from "semantic-ui-react";
import { usePatient, useAuth } from "../../hooks";
import { SinglePatient } from "../../components/Client";
import { Link } from 'react-router-dom';

export function PatientContent() {
    const { formstep, assignStep } = useAuth();
    const { loading, patient, getPatient } = usePatient();
    const [ isLoaded, setIsLoaded ] = useState(false);
    
    const { id } = useParams();
    var idDef = parseInt(id);
    const [ submitText, setSubmitText ] = useState("ADD NEW RECHARGE");
   

    useEffect(() => getPatient (idDef), []);

    useEffect(() => {
        if(patient){
            setIsLoaded(true);
          
            if (patient.recharges.length===0){
                setSubmitText("ADD FIRST RECHARGE")
               
            }
        }
    }, [patient])

 

    if(isLoaded){
        return (
            <>
                {loading ? (
                        <Loader active inline="centered">
                            Loading...
                        </Loader>
                ) : (
                        <div>
                            <div className=" headerpatientprofile">
                                <h1>Patient ID: {patient.patientid}</h1>
                                <Link className="ui link tertiary" to={`/edit-patient/${patient.id}`} >Edit Patient</Link>
                                <br></br> <br></br> <br></br>
                            </div>
                            <SinglePatient recharges={patient.recharges} patient={patient}/>
                            <div className="text-center">
                                <Link onClick={assignStep(1)} to="/add-recharge" className="ui fluid primary button stickybutton" state={{ patientid: patient.id, patient: patient.patientid, pump: patient.pump }}
                                >{submitText}</Link>
                            </div>
                        </div>
                )}

            </>
        )
    } else {
        return (
            <>

            </>
        )
    }



}
