import React from 'react';
import { useAuth } from "../../hooks";
import { Navigate } from "react-router-dom";


export function Logout() {
    const {logout} = useAuth();
       

    return <div>
            {logout()}

            <Navigate to="/" />
           </div>
}
