import { BASE_API } from "../utils/constants";

export async function getDrugsAPI(token){
    try {
        const url = `${BASE_API}api/drugs/`;
        const params = {
            headers:{
                Authorization: `Token ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json();
        localStorage.setItem('drugs', JSON.stringify(result));
        return result;

    } catch(error) {
        if(localStorage.getItem('drugs')){
            const result = localStorage.getItem('drugs');
            return result;
        } else {
            throw error;
        } 
    }
}